import {
    GET_DOCUMENTS,
    GET_RESOURCES,
    GET_LOW_AREAS,
    CLEAR_LOWAREAS,
    CLEAR_RESOURCES,
    GET_DOCUMENT_HTML,
    GET_DOCUMENT_BY_ID,
    CLEAR_DOCUMENTS,
    GET_LOW_AREAS_BY_ID,
    GET_RESOURCES_BY_ID,
    DOCUMENT_UPDATED,
} from "./../actions/types";

const INITIAL_STATE = {
    documentHTML: null,
    documents: {allDocuments: [], count: null, next: null},
    resources: {allResources: [], count: null, next: null},
    lowAreas: {allLowAreas: [], count: null, next: null},
    documentById: {},
    lowAreasById: {},
    resourcesById: {},
    updatedDocument: {},
};

export default function (state = INITIAL_STATE, action) {

    switch (action.type) {

        case GET_DOCUMENTS:
            return {
                ...state,
                documents: {
                    ...state.documents,
                    count: action.payload.data.count,
                    allDocuments: action.payload.config.url.includes("page")
                        ? [...state.documents.allDocuments, ...action.payload.data.results]
                        : [...action.payload.data.results],
                    next: action.payload.data.next
                }
            };
        case CLEAR_DOCUMENTS: {
            return {
                ...state,
                documents: { allDocuments: [], count: null, next: null}
            }
        }
        case GET_DOCUMENT_HTML:
            return {
                ...state,
                documentHTML: action.payload
            };
        case GET_RESOURCES:
            return {
                ...state,
                resources: {
                    ...state.resources,
                    count: action.payload.data.count,
                    next: action.payload.data.next,
                    allResources: action.payload.config.url.includes("page")
                        ? [...state.resources.allResources, ...action.payload.data.results]
                        : [...action.payload.data.results]
                }
            };
        case GET_LOW_AREAS:
            return {
                ...state,
                lowAreas: {
                    ...state.lowAreas,
                    count: action.payload.data.count,
                    next: action.payload.data.next,
                    allLowAreas: action.payload.config.url.includes("page")
                        ? [...state.lowAreas.allLowAreas, ...action.payload.data.results]
                        : [...action.payload.data.results]
                }
            };
        case CLEAR_LOWAREAS:
            return {
                ...state,
                lowAreas: {allLowAreas: [], count: null, next: null}
            };
        case CLEAR_RESOURCES:
            return {
                ...state,
                resources: {allResources: [], count: null, next: null}
            };
        case GET_DOCUMENT_BY_ID:
            return {
                ...state,
                documentById: action.payload
            };
        case GET_LOW_AREAS_BY_ID:
            return {
                ...state,
                lowAreasById: action.payload
            };
        case GET_RESOURCES_BY_ID:
            return {
                ...state,
                resourcesById: action.payload
            };
        case DOCUMENT_UPDATED:
            return {
                ...state,
                // updatedDocument: action.payload.data,
                // documentById: action.payload
            };
        default:
            return state;
    }
}
