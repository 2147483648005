import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Documents from './documents';
import Admin from './adminPart';
import logo from "../../../assets/img/logo.png";

class Aside extends Component {

  render() {
    const path = this.props.location.pathname;
    let role;
    localStorage.getItem("auth")
        ? role = localStorage.getItem("role")
          : role = sessionStorage.getItem("role");

    return (
      /* Aside start */
      <aside>
          <div className="header">
              <div className="logo">
                  <a href="/">
                      <img src={logo} alt="Brand logo" />
                  </a>
              </div>
          </div>
        <Documents
          count={this.props.documentsCount}
          role={role}
          path={path}
        />
        {
          role === '2'? <Admin
              path={path}
          />: null
        }
      </aside>
      /* Aside end */
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
    documentsCount: state.documents.documents.count,
    next: state.documents.documents.next,
  }
};

export default connect(mapStateToProps)(withRouter(Aside));
