import React, { Component, Fragment } from "react";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { confirmEditor } from "./../../store/actions/auth";
import SignIn from "./../Homepage/login/login";


class ConfirmationEditor extends Component {
  render() {
    const {
      editorConfirm,
      values,
      errors,
      touched,
      editorConfirmData
    } = this.props;

    return (
      <div className="popup-content">
        {!editorConfirm ? (
          <Fragment>
            <h3 className="popup-title">Editor: finalize registration</h3>
            <Form>
              {editorConfirmData && (
                <div className="flex">
                  <div className="text-field br-corner w-50 mr-2 disabled">
                    <input type="text" value={editorConfirmData.first_name} />
                  </div>
                  <div className="text-field br-corner w-50 ml-2 disabled">
                    <input type="text" value={editorConfirmData.last_name} />
                  </div>
                </div>
              )}
              {editorConfirmData && (
                <div className="text-field br-corner disabled">
                  <input type="email" value={editorConfirmData.email} />
                </div>
              )}
             
              <div
                className={`text-field br-corner ${touched.password &&
                  errors.password &&
                  "invalid"}`}
              >
                <Field
                  type="password"
                  placeholder="Password*"
                  name="password"
                  value={values.password}
                />
                {touched.password && errors.password && (
                  <p className="error-msg">{errors.password}</p>
                )}
              </div>
              <div
                className={`text-field br-corner ${touched.confirmPassword &&
                  errors.confirmPassword &&
                  "invalid"}`}
              >
                <Field
                  type="password"
                  placeholder="Confirm password*"
                  name="confirmPassword"
                  value={values.confirmPassword}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <p className="error-msg">{errors.confirmPassword}</p>
                )}
                {errors.role && <p className="error-msg">{errors.role}</p>}
              </div>
              <button
                type="submit"
                className="btn filled primary br-round shadow h-lg w-lg mb-4"
              >
                CONFIRM
              </button>
            </Form>
          </Fragment>
        ) : (
          <Fragment>
            <p className="message">
              Thanks for finalizing your registration. <br /> Now you can Sign
              in.
            </p>
            <button
              type="button"
              className="btn filled primary br-round shadow h-lg w-lg mb-4"
              onClick={() => this.props.onOpenModal(SignIn)}
            >
              DONE
            </button>
          </Fragment>
        )}
      </div>
    );
  }
}

const ConfirmationEditorFormik = withFormik({
  mapPropsToValues({ password, confirmPassword }) {
    return {
      password: password || "",
      confirmPassword: confirmPassword || ""
    };
  },
  validationSchema: Yup.object().shape({
    password: Yup.string()
      .min(6, "Please provide a valid password.")
      .max(20, "Please provide a valid password.")
      .required("This field is mandatory."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .required("This field is mandatory.")
  }),

  async handleSubmit(values, { props, setErrors, setValues }) {
    const handleErrors = {
      password: "Something went wrong"
    };
    const data = {
      password: values.password
    };

    const res = await props.confirmEditor(data, props.editorToken);

    res && res.payload.status === 200 && props.handleClick('editorConfirm');
    if (res && res.payload.status === 400) {
      const errors = {};
      Object.keys(res.payload.data).map(
        data => (errors[data] = handleErrors[data])
      );
      setErrors({ ...errors });
    }
  }
})(ConfirmationEditor);

export default connect(
  null,
  { confirmEditor }
)(ConfirmationEditorFormik);
