import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form, Field, validateYupSchema } from 'formik';
import * as Yup from 'yup';
import loadImage from 'blueimp-load-image';

import { JURISDICTIONS, PRACTICE_AREA, ORG_TYPES, BOOLEAN } from '../Homepage/options';

import { getContributor, updateContributor } from '../../store/actions/auth';
import ReactSelect from '../Homepage/select';

import avatarImage from '../../assets/img/user-avatar.png';
import PhoneNumber from '../../helpers/phoneNumberInput';
import Modal from 'react-responsive-modal';
import ChangePassword from '../../helpers/changePassword';
import SuccessPopup from '../Homepage/successPopup';
import improveUrl, { handleLoguot } from '../../helpers/improve';

const SUPPORTED_FORMATS = ['image/jpeg', 'image/png'];

class ProfilePage extends Component {
  state = {
    open: false,
    phone_number: '',
    phoneNumberError: '',
    first: false,
    second: false,
    third: false,
    email: '',
    success: false,
    changeEmail: false,
    profile_pic: null,
    currentEmail: '',
    passwordTouched: false,
    isLicensedInactive: true
  };

  onOpenModal = () => {
    this.setState({ open: true })
  };

  onCloseModal = type => {
    if (type === 'emailIsChanged') {
      this.props.setValues({ ...this.props.values, emailIsChanged: false })
    } else {
      this.setState({ open: false, success: false })
    }
  };

  onPasswordSuccess = () => {
    this.setState({ success: true })
  };

  handleNumberTouched = value => {
    !value && this.setState({ phoneNumberError: 'This field is mandatory.' })
  };

  handleNumber = value => {
    this.props.setValues({
      ...this.props.values,
      phone_number: value
    });
    this.setState({ phone_number: value, phoneNumberError: '' })
  };

  onChangeLicensed = ({ value }) => {
    this.setState({ isLicensedInactive: !value })
  };

  profilePicDeleteHandler = e => {
    this.props.setValues({
      ...this.props.values,
      picture: null,
      profile_pic: null,
    });
    this.setState({ profile_pic: null })
  };

  upload = async e => {
    const { setErrors, errors } = this.props;
    const f = e.target.files[0];

    if (!SUPPORTED_FORMATS.includes(f && f.type)) {
      await setErrors({ ...errors, profile_pic: 'Profile picture format should be JPG or PNG.' })
      e.persist();
      e.preventDefault();
      // return;
    } else if (f.size / 1000000 > 10) {
      await setErrors({ ...errors, profile_pic: 'File size must be less than 10 MB' })
      e.persist();
      e.preventDefault();
    } else {
      this.props.setValues({
        ...this.props.values,
        picture: f
      });

      loadImage(
        f,
        canvas => {
          canvas.toBlob(blob => {

            var reader = new FileReader();
            reader.onloadend = () => {
              const dataURL = reader.result;
              this.props.setValues({
                ...this.props.values,
                profile_pic: dataURL,
                picType: f.type
              })
            }

            this.setState({ profile_pic: null })
            reader.readAsDataURL(blob)

          })
        },
        {
          canvas: true,
          orientation: true,
          maxMetaDataSize: 262144,
          maxWidth: 128,
          maxHeight: 128
        }
      )
    }

  }


  async componentDidMount () {
    const { setValues, history } = this.props
    const id = localStorage.getItem('id') || sessionStorage.getItem('id')
    const res = await this.props.getContributor(id);
    if (res.type === "auth_error"){
      handleLoguot();
      return history.push('/');
    }
    const profile = res && res.payload && res.payload.data;
    const jurisdictions = []
    if (profile) {
      let is_licensed = profile && BOOLEAN.filter(({ value }) => value === profile.is_licensed)[0]
      let organization_type = ORG_TYPES.filter(({ value }) => value === profile.organization_type)[0]
      let practice_areas = PRACTICE_AREA.filter(({ value }) => value === profile.practice_areas[0])[0]
      let profile_pic = profile.profile_pic

      profile_pic && this.setState({ profile_pic: profile.profile_pic })

      profile && profile.jurisdictions.map(data =>
        jurisdictions.push(...JURISDICTIONS.filter(({ value }) => value === data))
      )

      let phone_number = profile && profile.phone_number || ''
      if (phone_number && phone_number.length && phone_number[0] !== '+') {
        phone_number = '+' + phone_number
      }

      setValues({
        ...profile,
        first_name: profile.first_name || '',
        last_name: profile.last_name || '',
        email: profile.email || '',
        organization_name: profile.organization_name || '',
        organization_city: profile.organization_city || '',
        organization_address: profile.organization_address || '',
        organization_url: profile.organization_url || '',
        other_country: profile.other_country || '',
        phone_number,
        jurisdictions,
        practice_areas: { ...practice_areas },
        organization_type,
        is_licensed: { ...is_licensed },
        profile_pic,
        currentEmail: profile.email
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    const { errors } = this.props;

    if (errors.isValidate){
      const invalidField = Object.keys(this.props.errors)[0];
      this[invalidField].focus();
    }

    if (prevProps.isSubmitting && !this.props.isSubmiting && !this.props.isValid){
      const invalidField = Object.keys(errors)[0];
      this[invalidField].focus();
    }

  }

  handleHide = num => {
    this.setState(prevState => {
      return { [num]: !this.state[num] }
    })
  }

  onCloseEmailPopup = () => {
    this.props.setValues({ ...this.props.values, emailIsChanged: false })
  }

  onCloseProfilePopup = () => {
    this.props.setValues({ ...this.props.values, profileIsChanged: false })
  };

  render () {
    const { open, first, second, third, success, profile_pic, } = this.state;
    const { upload } = this;

    const {
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
    } = this.props

    return (
      <div>
        <main className="admin-content manage">
          {/* {profile && ( */}
          <div className="container-sm">
            <h2 className="font-secondary fw-600 mb-5">PROFILE</h2>
            <Form>
              <ul>
                <div className="card br-round mb-2">
                  <div
                    className="flex justify-between align-center pr-10 pl-10 border-bottom primary-10 pt-3 pb-3 hide">
                    <h5 className="color-primary fw-700 font-secondary text-uppercase mr-3">
                      PERSONAL INFORMATION
                    </h5>
                    <button
                      type={'button'}
                      className="btn-icon"
                      onClick={() => this.handleHide('first')}
                    >
                      <i className={`icon-arrow-${first ? 'down' : 'up'} color-primary`}>{''}</i>
                    </button>
                  </div>
                  <div
                    className={`pr-10 pl-10 pt-4 pb-6 flex card-content ${
                      first ? 'hide' : ''
                    }`}
                  >
                    <div
                      className={`user-image text-xs-center ${
                        values.profile_pic ? 'uploaded' : ''
                      }`}
                    >
                      <div
                        className="image"
                        style={{
                          backgroundImage: `url(${
                            profile_pic
                              ? profile_pic
                              : values.profile_pic
                              ? values.profile_pic
                              : avatarImage
                          })`
                        }}
                      >
                        {''}
                        <div className="hover-content">
                          <label>
                            <input
                              type="file"
                              name="profile_pic"
                              onChange={upload}
                              accept="image/png, image/jpeg"
                            />
                            Upload new <br/> picture
                          </label>
                        </div>


                      </div>
                      {!values.profile_pic && (
                        <label className="image-upload">
                          <input
                            ref={ref => this.profile_pic = ref }
                            type="file"
                            name="profile_pic"
                            onChange={upload}
                            accept="image/png, image/jpeg"
                          />
                          <i className="icon-camera">{''}</i>
                          Upload Image
                        </label>

                      )}

                      {errors.profile_pic && (
                        <p className="error-msg text-center">{errors.profile_pic}</p>
                      )}

                      {values.profile_pic && (
                        <button
                          type={'button'}
                          className="btn-icon color-warning flex align-center mt-4"
                          onClick={this.profilePicDeleteHandler}
                        >
                          <i className="icon-delete mr-2 fs-lg">{''}</i>Delete
                        </button>
                      )}

                    </div>

                    <div className="user-details">
                      <div className="flex">
                        <div className="w-50 mr-2">
                          <div
                            className={`text-field br-corner  ${touched.first_name &&
                            errors.first_name &&
                            'invalid'}`}
                          >
                            <label>First name*</label>
                            <Field
                              innerRef={ref => this.first_name = ref }
                              type="text"
                              name="first_name"
                              value={values.first_name}
                            />

                            {touched.first_name && errors.first_name && (
                              <p className="error-msg">{errors.first_name}</p>
                            )}
                          </div>
                        </div>
                        <div className="w-50 ml-2">
                          <div
                            className={`text-field br-corner  ${touched.last_name &&
                            errors.last_name &&
                            'invalid'}`}
                          >
                            <label>Last name*</label>
                            <Field
                              innerRef={ref => this.last_name = ref }
                              type="text"
                              name="last_name"
                              value={values.last_name}
                            />
                            {touched.last_name && errors.last_name && (
                              <p className="error-msg">{errors.last_name}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`text-field br-corner  ${touched.email &&
                        errors.email &&
                        'invalid'}`}
                      >
                        <label>Email address*</label>
                        <Field
                          innerRef={ ref => this.email = ref }
                          type="text"
                          name="email"
                          value={values.email}
                        />
                        {touched.email && errors.email && (
                          <p className="error-msg">{errors.email}</p>
                        )}
                      </div>
                      {(values.currentEmail === values.email.trim()) && touched.password && (
                        setFieldTouched('password', false)
                      )}

                      {values.currentEmail !== values.email.trim()
                      && (errors.email !== 'Please provide a valid email.')
                      && (
                        <div
                          className={`text-field br-corner ${touched.password &&
                          errors.password
                          && 'invalid'}`}
                        >
                          <label>Password*</label>
                          <Field
                            innerRef={ref => this.password = ref }
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={values.password}

                          />
                          {errors.password && touched.password && (
                            <p className="error-msg">{errors.password}</p>
                          )}

                          {touched.password && !values.password && (
                            <p className="error-msg">This field is mandatory </p>
                          )}

                        </div>
                      )}
                      <div
                        className={`text-field br-corner ${touched.phone_number &&
                        errors.phone_number &&
                        'invalid'}`}
                      >
                        <label>Phone number*</label>
                        <PhoneNumber
                          handleNumber={this.handleNumber}
                          value={values.phone_number}
                          onBlur={this.handleNumberTouched}
                        />
                        {this.state.phoneNumberError && (
                          <p className="error-msg">{this.state.phoneNumberError}</p>
                        )}
                      </div>

                      <button
                        className="btn-icon color-primary fs-md ml-5"
                        type="button"
                        onClick={this.onOpenModal}
                      >
                        {' '}
                        <i className="icon-lock mr-3">{''}</i>Change Password
                      </button>
                      {/* Change password popup start */}
                      {/* arandzin component sarqel */}

                      <Modal open={open} onClose={this.onCloseModal} center>
                        {!success ? (
                          <ChangePassword
                            onPasswordSuccess={this.onPasswordSuccess}
                          />
                        ) : (
                          <SuccessPopup
                            text={'Your password has been changed successfully!'}
                            onClose={this.onCloseModal}
                            icon="lock"
                            title={false}
                            modalName="modalSignIn"
                          />
                        )}
                      </Modal>

                      <Modal
                        onClose={this.onCloseEmailPopup}
                        open={values.emailIsChanged}
                      >
                        {values.emailIsChanged && (
                          <SuccessPopup
                            text={'You are about to change your email address. Please ' +
                            'check your new email to finalize the Email change process.'}
                            onClose={this.onCloseEmailPopup}
                            icon="envelop"
                            title={false}
                            modalName="modalSignIn"
                          />
                        )}
                      </Modal>

                      <Modal
                        onClose={this.onCloseProfilePopup}
                        open={values.profileIsChanged}
                      >
                        {values.profileIsChanged && (
                          <SuccessPopup
                            text={'Your profile information has been successfully updated.'}
                            onClose={this.onCloseProfilePopup}
                            icon="lock"
                            title={false}
                            modalName="modalSignIn"
                          />
                        )}
                      </Modal>
                      {/* Change password popup end */}
                    </div>
                    {/* User details end */}
                  </div>
                </div>
              </ul>
              <div className="card br-round mb-2">
                <div className="flex justify-between align-center pr-10 pl-10 border-bottom primary-10 pb-3 pt-3">
                  <h5 className="color-primary fw-700 font-secondary text-uppercase mr-3">
                    PRACTICE INFORMATION
                  </h5>
                  <button
                    type={'button'}
                    className="btn-icon"
                    onClick={() => this.handleHide('second')}
                  >
                    <i className={`icon-arrow-${second ? 'down' : 'up'} color-primary`}>{''}</i>
                  </button>
                </div>
                <div
                  className={`pr-10 pl-10 pt-4 card-content pb-6 ${
                    second ? 'hide' : ''
                  }`}
                >
                  <div className="select-box br-corner">
                    <label>Are you licensed to practice law?*</label>
                    <ReactSelect
                      placeholder=" Are you licensed to practice law?*"
                      options={BOOLEAN}
                      name="is_licensed"
                      value={values.is_licensed}
                      errors={errors.is_licensed}
                      touched={touched.is_licensed}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </div>
                  <div className="select-box br-corner">
                    <label>In which jurisdictions are you licensed?</label>
                    <ReactSelect
                      placeholder="In which jurisdictions are you licensed?"
                      options={JURISDICTIONS}
                      errors={errors.jurisdictions}
                      value={values.jurisdictions}
                      touched={touched.jurisdictions}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      name={'jurisdictions'}
                      isDisabled={values.is_licensed.label === 'No'}
                    />
                  </div>

                  <div
                    className={`text-field br-corner ${touched.other_country &&
                    errors.other_country &&
                    'invalid'}`}
                  >
                    <label>Other country</label>
                    <Field
                      type="text"
                      placeholder="Other country"
                      name="other_country"
                      value={values.other_country}
                    />
                    {touched.other_country && errors.other_country && (
                      <p className="error-msg">{errors.other_country}</p>
                    )}
                  </div>
                  <div className="select-box br-corner">
                    <label>Which are your primary areas of practice?*</label>
                    <ReactSelect
                      placeholder="Which are your primary areas of practice?*"
                      options={PRACTICE_AREA}
                      errors={errors.practice_areas}
                      touched={touched.practice_areas}
                      value={values.practice_areas}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      name={'practice_areas'}
                    />
                  </div>
                </div>
              </div>
              <div className="card br-round mb-5">
                <div className="flex justify-between align-center pr-10 pl-10 border-bottom primary-10 pb-3 pt-3">
                  <h5 className="color-primary fw-700 font-secondary text-uppercase mr-3">
                    ORGANIZATION INFORMATION
                  </h5>
                  <button
                    type={'button'}
                    className="btn-icon"
                    onClick={() => this.handleHide('third')}
                  >
                    <i className={`icon-arrow-${third ? 'down' : 'up'} color-primary`}>{''}</i>
                  </button>
                </div>
                <div
                  className={`pr-10 pl-10 pt-4 pb-6 card-content ${
                    third ? 'hide' : ''
                  }`}
                >
                  <div
                    className={`text-field br-corner ${touched.organization_name &&
                    errors.organization_name &&
                    'invalid'}`}
                  >
                    <label>Organization name*</label>
                    <Field
                      type="text"
                      placeholder="Organization name*"
                      name="organization_name"
                      value={values.organization_name}
                    />
                    {touched.organization_name && errors.organization_name && (
                      <p className="error-msg">{errors.organization_name}</p>
                    )}
                  </div>

                  <div className="select-box br-corner">
                    <label>Organization type*</label>
                    <ReactSelect
                      placeholder="Organization type*"
                      options={ORG_TYPES}
                      value={values.organization_type}
                      errors={errors.organization_type}
                      touched={touched.organization_type}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      name={'organization_type'}
                    />
                  </div>
                  <div
                    className={`text-field br-corner ${touched.organization_address &&
                    errors.organization_address &&
                    'invalid'}`}
                  >
                    <label>Address of the organization</label>
                    <Field
                      type="text"
                      placeholder="Address of the organization"
                      name="organization_address"
                      value={values.organization_address}
                    />
                    {touched.organization_address &&
                    errors.organization_address && (
                      <p className="error-msg">
                        {errors.organization_address}
                      </p>
                    )}
                  </div>
                  <div
                    className={`text-field br-corner ${touched.organization_city && errors.organization_city && 'invalid'} `}
                  >
                    <label>City of the organization</label>
                    <Field
                      type="text"
                      placeholder="City of organization"
                      name="organization_city"
                      value={values.organization_city}
                    />
                    {touched.organization_city && errors.organization_city && (
                      <p className="error-msg">{errors.organization_city}</p>
                    )}
                  </div>
                  <div
                    className={`text-field br-corner ${touched.organization_url &&
                    errors.organization_url &&
                    'invalid'}`}
                  >
                    <label>Organization's Website</label>
                    <Field
                      type="text"
                      placeholder="Organization's website"
                      name="organization_url"
                      value={values.organization_url}
                    />
                    {touched.organization_url && errors.organization_url && (
                      <p className="error-msg">{errors.organization_url}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="text-xs-center">
                <button
                  className="btn filled primary br-round shadow h-lg w-xl"
                  type="submit"
                >
                  SAVE CHANGES
                </button>
              </div>
            </Form>
          </div>
          {/* )} */}
        </main>

      </div>
    )
  }
}

const ProfileFormik = withFormik({
  mapPropsToValues () {
    return {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      is_licensed: [],
      jurisdictions: [],
      practice_areas: [],
      organization_name: '',
      organization_type: [],
      organization_city: '',
      organization_address: '',
      organization_url: '',
      password: '',
      profile_pic: '',
      other_country: '',
    }
  },

  validationSchema: Yup.object().shape({
    first_name: Yup.string()
    .max(200, 'Maximum number of symbols is 200.')
    .trim()
    .required('This field is mandatory.'),
    last_name: Yup.string()
    .max(200, 'Maximum number of symbols is 200.')
    .trim()
    .required('This field is mandatory.'),
    email: Yup.string()
    .email('Please provide a valid email.')
    .required('This field is mandatory.'),
    organization_url: Yup.string()
    .max(200, 'cant be more than 200 characters')
    .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
      'The provided link should be valid.'),
    is_licensed: Yup.string().required('This field is mandatory.'),
    organization_name: Yup.string()
    .required('This field is mandatory.')
    .max(200, 'Maximum number of symbols is 200.'),
    organization_type: Yup.string().required('This field is mandatory.'),
    other_country: Yup.string().max(200, 'Maximum number of symbols is 200.'),
    organization_city: Yup.string().max(200, 'Maximum number of symbols is 200.'),
    organization_address: Yup.string().max(200, 'Maximum number of symbols is 200.'),
    password: Yup.string()
    .min(6, 'The Password field must be 6 or more characters.')
    .max(20, 'Please provide a valid password.')
  }),

  handleSubmit: async (values, { props, setErrors, setValues, errors, resetForm, setSubmitting }) => {

    let phone_number = values.phone_number || ''
    if (phone_number && phone_number.length && phone_number[0] === '+') {
      phone_number = phone_number.slice(1)
    }

    if (values.email !== values.currentEmail && !values.password) {
      setErrors({ ...errors, password: 'This field is mandatory.', isValidate: true })
      return
    }

    const data = {
      profile_pic: values.picture || '',
      first_name: values.first_name || '',
      last_name: values.last_name || '',
      email: values.email || '',
      phone_number,
      jurisdictions: values.jurisdictions.map(data => data.value),
      is_licensed: values.is_licensed.value,
      organization_name: values.organization_name || '',
      practice_areas: values.practice_areas.value,
      organization_type: values.organization_type.value,
      organization_city: values.organization_city || '',
      organization_address: values.organization_address || '',
      organization_url: values.organization_url || '',
      other_country: values.other_country || '',
      password: values.password || '',
    };

    if (values.profile_pic && values.profile_pic.includes('http')){
      delete data.profile_pic;
    }

    if (data.is_licensed === false) {
      data.jurisdictions = []
    }

    if (data.organization_url) {
      data.organization_url = improveUrl(values.organization_url)
    }

    if (values.email === values.currentEmail) {
      delete data.password
    }

    function getFormData (object) {
      const formData = new FormData();
      Object.keys(object).forEach(key => {
        if (key === 'jurisdictions') {}
        formData.append(key, object[key])
      });
      return formData;
    }

    const form_data = getFormData(data);

    if (values.email !== values.currentEmail && !values.password) {
      setErrors({ ...errors, password: 'This field is mandatory.', isValidate: true })
    } else if (values.picType && !SUPPORTED_FORMATS.includes(values.picType)) {
      setErrors({ ...errors, profile_pic: 'Profile picture format should be JPG or PNG.', isValidate: true })
    } else {
      let emailIsChanged = false;
      if (values.currentEmail && values.email && values.currentEmail !== values.email) {
        emailIsChanged = true
      }

      const res = await props.updateContributor(
        form_data,
        localStorage.getItem('id')
      );
      if (res && res.payload && res.payload.status === 200) {
        if (emailIsChanged) {
          setValues({ ...values, emailIsChanged })
        } else {
          setValues({ ...values, profileIsChanged: true })
        }

      } else if (res.payload && (res.payload.status === 401)) {
        setErrors({ ...errors, password: 'Password is not correct.', isValidate: true })
      } else if (res && res.payload && res.payload.status === 400) {
        const error  = res.payload.data.email && res.payload.data.email.length && res.payload.data.email[0];
        if (error && error === "This field must be unique.") {
          setErrors({...errors, email: "This email already exists."})
        }else if (error && error === 'Enter a valid email address.'){
          setErrors({...errors, email: 'Please provide a valid email.'})
        }
      }
    }
  },

  enableReinitialize: true,

})(ProfilePage);

function mapStateToProps (state) {
  return {
    profile: state.auth.profile.data,
    outError: state.auth.outError,
  }
}

export default connect(
  mapStateToProps,
  { getContributor, updateContributor }
)(ProfileFormik)
