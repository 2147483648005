import React, { Component } from "react";
import logo from "./../../assets/img/logo.png";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";
import {
  confirmUser,
  removeToken,
  saveResetToken
} from "../../store/actions/auth";
import { modalBody } from "../../helpers/modalBody";
import SearchComponent from "./searchComponent";
import SignUp from "./register/signUp";
import SignIn from "./login/login";

class Header extends Component {
  state = {
    open: false,
    component: null
  };

  render() {
    const {
      component,
      token,
      lawyerModal,
      lawyerToken,
      handleClick,
      lawyerConfirm,
      onOpenModal,
      onCloseModal,
      showSearch,
      loading
    } = this.props;

    return (
      /* Header start */
      <header className={`main ${!showSearch ? "no-search" : ""}`}>
        <div className="container">
          <div className="nav-items">
            <div className="items-left flex align-center w-70">
              <div className="brand-logo">
                <a href="/">
                  <img src={logo} alt="Brand logo" />
                </a>
              </div>
              <ul className="nav">
                <li>
                  <a href="http://jmdorsey.com/" target="_blank">
                    By John Dorsey PLLC
                  </a>
                </li>
                {/*<li>
                  <a href="/">Contributing Lawyers</a>
                </li>*/}
                <li>
                  <Link to="/pricing">PRICING</Link>
                </li>
              </ul>
            </div>
            <div className="items-right flex align-center justify-end">
              {!token && !loading ? (
                <ul className="flex align-center">
                  <li>
                    <button
                      className="btn link white w-sm h-sm text-uppercase"
                      onClick={() =>
                        this.props.onOpenModal("modalSignIn", SignIn)
                      }
                    >
                      Log in
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn outline text-white br-corner borLinker-white-30 w-sm h-sm"
                      onClick={() =>
                        this.props.onOpenModal("modalSignUp", SignUp)
                      }
                    >
                      SIGN UP FREE
                    </button>
                  </li>
                </ul>
              ) : (
                <ul className="flex align-center">
                  <li>
                    <Link
                      to={"/user/profile"}
                      className="btn link white  br-corner w-sm h-sm"
                    >
                      PROFILE
                    </Link>
                  </li>
                  <li>
                    <button
                      className="btn link white w-sm h-sm text-uppercase"
                      onClick={this.props.handleLoguot}
                    >
                      LOG OUT
                    </button>
                  </li>
                </ul>
              )}

              {component && (
                <Modal
                  open={
                    this.props.modalSignIn ||
                    this.props.modalSignUp ||
                    this.props.modalReset ||
                    this.props.modalRegister ||
                    false
                  }
                  onClose={() =>
                    this.props.onCloseModal(
                      this.props.modalSignIn
                        ? "modalSignIn"
                        : this.props.modalSignUp
                        ? "modalSignUp"
                        : this.props.modalRegister
                        ? "modalRegister"
                        : "modalReset"
                    )
                  }
                >
                  {modalBody(
                    component,
                    onCloseModal,
                    lawyerModal,
                    lawyerToken,
                    handleClick,
                    onOpenModal,
                    lawyerConfirm
                  )}
                </Modal>
              )}
              {/* Sign up popup end */}
            </div>
          </div>
          {showSearch && (
            <div className="search">
              <div className="brand-info flex align-center">
                <p className="color-white fs-md">
                  Model legal documents with drafting tips <br /> for emerging
                  companies.
                </p>
              </div>
              <SearchComponent
                searchResults={this.props.searchResults}
                headerClass="mb-0"
                onHandleChange={this.props.onHandleChange}
                searchValue={this.props.searchValue}
              />
            </div>
          )}
        </div>
      </header>
      /* Header end */
    );
  }
}

export default connect(
  null,
  { removeToken, confirmUser, saveResetToken }
)(Header);
