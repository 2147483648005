import axios from "axios";
import { privateApi, publicApi } from "./../../http/axios";
import {
    GET_DOCUMENTS,
    GET_RESOURCES,
    GET_LOW_AREAS,
    CLEAR_RESOURCES,
    CREATE_LOW_AREAS,
    DELETE_LOW_AREAS,
    CREATE_DOCUMENTS,
    CREATE_RESOURCES,
    DELETE_RESOURCES,
    GET_DOCUMENT_HTML,
    ERROR,
    CLEAR_LOWAREAS,
    GET_DOCUMENT_BY_ID,
    GET_LOW_AREAS_BY_ID,
    GET_RESOURCES_BY_ID,
    CLEAR_DOCUMENTS,
    DOCUMENT_UPDATED,
} from "./types";

import { urlNormalizer } from '../../helpers/improve';
import { DOCUMENT_DELETED } from './types.js'

export const getDocuments = (admin, resourseTypes, page, searchValue = '', is_publish, role) => dispatch => {

    if (resourseTypes && (resourseTypes[0] === "" || !resourseTypes.length)) {
        resourseTypes = null;
    }

    let url;

    if (role === 'lawyer'){
        url = `/documents/${ resourseTypes ? ( '?' + resourseTypes.join("&") + '&')
            : "?"}${(is_publish === false )? 'is_published=false': ''}${page ? `&page=${page}`: ""}`;
    } else {
        url = `/documents/${ resourseTypes ? ( '?' + resourseTypes.join("&") + '&')
            : "?"}${(is_publish === false )? 'is_published=false': 'is_published=true'}${page ? `&page=${page}`: ""}`;
    }

    const lastChar = url[url.length - 1];

    console.log(url);

    if (lastChar === '?' || lastChar === '&'){
        url = url.slice(0, -1)
    }

    return ((admin === 'admin') ? privateApi : publicApi)
        .get(url)
        .then(res => {
            return dispatch({
                type: GET_DOCUMENTS,
                payload: res,
            });
        })
        .catch(err => {
            return err.response;
        });
};


export const getDocumentsForUsers = (user, resourseTypes, page, searchValue = '', is_publish = false) => dispatch => {
    const token = localStorage.auth || sessionStorage.auth;

    if (resourseTypes && (resourseTypes[0] === "" || !resourseTypes.length)) {
        resourseTypes = null;
    }

    let url = `/documents/${ resourseTypes ? ( '?' + resourseTypes.join("&") + '&') : "?"}${page ? `&page=${page}`: ""}`;

    const lastChar = url[url.length - 1];

    if (lastChar === '?' || lastChar === '&'){
        url = url.slice(0, -1)
    }

    return (token ? privateApi : publicApi)
      .get(url)
      .then(res => {
          return dispatch({
              type: GET_DOCUMENTS,
              payload: res,
          });
      })
      .catch(err => {
          return err.response;
      });
};


export const getDocumentByIdWithPermission = (id) => dispatch => {
    const url = `/documents/${id}/`;
    return privateApi
      .get(url, )
      .then(res => {
          return dispatch({
              type: GET_DOCUMENT_BY_ID,
              payload: res
          });
      })
      .catch(err => {
          return err.response;
      });
};

export const getDocumentById = (id) => dispatch => {
    const url = `/documents/${id}/`;
    return publicApi
        .get(url, )
        .then(res => {
            return dispatch({
                type: GET_DOCUMENT_BY_ID,
                payload: res
            });
        })
        .catch(err => {
            return err.response;
        });
};

export const getDocumentHTML = url => dispatch => {
    const myUrl = `${url}?${Math.random()}`;
    return axios
        .get(myUrl)
        .then(res => {
            return dispatch({
                type: GET_DOCUMENT_HTML,
                payload: res
            });
        })
        .catch(err => {
            return err.response;
        });
};

export const documentDownload = (id, data) => dispatch => {
    const url = `/download/${id}/?type=${data.chooseFormat}&include_notes=${data.chooseOption}`;
    return privateApi
        .get(url, {
            responseType: 'blob'
        })
        .then(response => {
            const fileName = response.headers['content-disposition'].split('=')[1];
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.setAttribute('download', fileName);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(err => {
        })
};

export const documentUpdate = (id, data) => dispatch => {
    const url = `/documents_update/${id}/`;
    return privateApi
        .put(url, data)
        .then(res => {
            return dispatch({
                type: DOCUMENT_UPDATED,
                payload: res,
            });
        })
        .catch(err => {
            return dispatch({
                type: ERROR,
                payload: err.response,
            });
        });
};

export const documentPublish = id => dispatch => {
    const url = `/documents_update/${id}/`;

    return privateApi
        .patch(url, {is_published: true})
        .then(res => {
            return dispatch({
                type: DOCUMENT_UPDATED,
                payload: res,
            });
        })
        .catch(err => {
            return dispatch({
                type: ERROR,
                payload: err.response,
            });
        });
};

export const documentDelete = (id, has_subscribers) => dispatch => {
    const url = `/documents/${id}/${has_subscribers? '?confirm=true': ''}`;

    return privateApi
        .delete(url)
        .then(res => {
            return dispatch({
                type: DOCUMENT_DELETED,
                payload: res,
            });
        })
        .catch(err => {
            return dispatch({
                type: ERROR,
                payload: err.response,
            });
        });
};

export const getResourceTypes = page => dispatch => {
    const url = `/resource_types/${page ? `?page=${page}` : ""}`;
    return publicApi
        .get(url)
        .then(res => {
            return dispatch({
                type: GET_RESOURCES,
                payload: res
            });
        })
        .catch(err => {
            return err.response;
        });
};

export const createResourceTypes = data => dispatch => {
    const url = `/resource_types/`;
    return privateApi
        .post(url, data, {})
        .then(res => {
            return dispatch({
                type: CREATE_RESOURCES,
                payload: res
            });
        })
        .catch(err => {
            return dispatch({
                type: ERROR,
                payload: err.response
            });
        });
};

export const deleteResourceType = id => dispatch => {
    const url = `/resource_types/${id}/`;
    return privateApi
        .delete(url)
        .then(res => {
            return dispatch({
                type: DELETE_RESOURCES,
                payload: res
            });
        })
        .catch(err => {
            return dispatch({
                type: ERROR,
                payload: err.response
            });
        });
};

export const getLowAreas = page => dispatch => {
    const url = `/law_areas/${page ? `?page=${page}` : ""}`;
    return publicApi
        .get(url)
        .then(res => {
            return dispatch({
                type: GET_LOW_AREAS,
                payload: res
            });
        })
        .catch(err => {
            return err.response;
        });
};

export const getLowAreasById = id => dispatch => {
    const url = `/law_areas/${id}/`;
    return privateApi
        .get(url)
        .then(res => {
            return dispatch({
                type: GET_LOW_AREAS_BY_ID,
                payload: res
            });
        })
        .catch(err => {
            return err.response;
        });
};
export const getResourcesById = id => dispatch => {
    const url = `/resource_types/${id}/`;
    return privateApi
        .get(url)
        .then(res => {
            return dispatch({
                type: GET_RESOURCES_BY_ID,
                payload: res
            });
        })
        .catch(err => {
            return err.response;
        });
};

export const clearDocuments = payload => ({
    type: CLEAR_DOCUMENTS,
    payload
});

export const clearLowAreas = payload => ({
    type: CLEAR_LOWAREAS,
    payload
});

export const clearResourceTypes = payload => ({
    type: CLEAR_RESOURCES,
    payload
});

export const createLowAreas = data => dispatch => {

    const url = `/law_areas/`;
    return privateApi
        .post(url, data)
        .then(res => {
            return dispatch({
                type: CREATE_LOW_AREAS,
                payload: res
            });
        })
        .catch(err => {
            return dispatch({
                type: ERROR,
                payload: err.response
            });
        });
};

export const deleteLowAreas = id => dispatch => {
    const url = `/law_areas/${id}/`;
    return privateApi
        .delete(url)
        .then(res => {
            return dispatch({
                type: DELETE_LOW_AREAS,
                payload: res
            });
        })
        .catch(err => {
            return dispatch({
                type: ERROR,
                payload: err.response
            });
        });
};

export const createDocuments = (data, token, callback) => dispatch => {
    const url = `/documents/`;

    return privateApi
        .post(url, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: progressEvent => callback(progressEvent)
        })
        .then(res => {
            return dispatch({
                type: CREATE_DOCUMENTS,
                payload: res
            });
        })
        .catch(err => {
            return err.response;
        });
};
