import React from "react";


/**
 * @description Component for user plans.
 * @param plan - plan item.
 * @param subscription - Subscription of the user.
 * @param benefits - Plans benefits.
 * @param purchaseHandler - Purchases the plan.
 * @param cancelSubscription - Cancel plan subscription.
 * @param is_bought - Document is bought or not by user.
 */
export default function Plan({ plan, benefits, subscription, purchaseHandler, cancelSubscription, is_bought }) {
    let isCurrent = false;
    let isCancelable = false;
    let isDisable = false;

    if (subscription && subscription.plan){
        if (subscription.subscription_type === 1){
            //Subscription is annual
            if (plan.id === subscription.plan.id){
                isCancelable = !subscription.cancel_at_period_end;
                isCurrent = true;
            } else {
                isDisable = true;
            }
        } else {
            if (plan.id === subscription.plan.id){
                isCurrent = true;
                isDisable = !!is_bought;
            }
        }
    }


    return (
        <li>
            <div className="plan-head">
                { isCurrent && <p className="current-plan">Your current plan</p> }
                <p className="plan-title">{plan.title}</p>
                <p className="plan-price">{plan.price === "TBD" ? "" : "$"}{plan.price}</p>
            </div>
            <div className="plan-body">
                <ul className="plan-features">
                    { plan.plan_benefits.map( benefit => <li key={benefit}>{ benefits[benefit] }</li>) }
                </ul>
                {
                    isCancelable? (
                        <button
                            type="button"
                            className="btn filled outline text-primary border-black-20 h-lg br-round w-xl"
                            onClick={() => cancelSubscription(subscription.id)}
                        >
                            Cancel Subscription
                        </button>
                    ):(
                        <button
                            onClick={() => purchaseHandler(plan)}
                            className="btn filled primary h-lg br-round shadow w-lg"
                            disabled={plan.price === "TBD" /*must be remove*/ || isDisable}
                        >
                            Purchase
                        </button>
                    )
                }
            </div>
        </li>
    );
}
