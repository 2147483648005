import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Modal from 'react-responsive-modal'
import {
  getDocumentsForUsers,
  getResourceTypes,
  getLowAreas,
  clearDocuments,
  clearLowAreas,
  clearResourceTypes,
} from '../../../store/actions/documents'
import Documents from './../documents'
import Resources from './resources'
import Header from './../header'
import Footer from './../footer'

import {
  removeToken,
  confirmUser,
  saveResetToken,
  contributorVerification,
  editorVerification,
  lawyerVerification,
  emailUpdate,
} from '../../../store/actions/auth';
import { removeSubscription } from '../../../store/actions/payment';

import { modalBody } from '../../../helpers/modalBody'
import SignIn from './../login/login'
import ResetPasswords from './../forgot-password/reset-password'
import SuccessRegister from './../successRegisterPopup'
import SuccessPopup from './../successPopup'
import ConfirmationEditor from '../confirmationEditor'
import Document from '../../common/document'
import SignUp from '../register/signUp';
import { handleLoguot } from '../../../helpers/improve';

class Homepage extends Component {
  state = {
    page: 1,
    searchValue: '',
    showresults: false,
    hasMore: true,
    documents: [],
    documentId: null,
    documentsCount: 0,
    query: {
      resources: [],
      lowAreas: []
    },
    queries: {
      resources: [],
      lowAreas: []
    },
    isLogedOut: false,
    component: null,
    success: false,
    searchTerm: '',
    editorModal: false,
    lawyerModal: false,
    editorConfirm: false,
    isLawyerConfirm: false,
    editorToken: null,
    lawyerToken: null,
    modalSignIn: false,
    modalEmail: false,
    modalSignUp: false,
    modalDocuments: false,
    modalReset: false,
    modalRegister: false,
    noResult: false,
    is_loading: true,
  };


  async componentDidMount () {
    if (this.props.location.state && this.props.location.state.value) {
      const { value } = this.props.location.state;
      const searchTerm = [`search_term=${value}`];
      await this.props.getDocumentsForUsers('homePage', searchTerm);
      this.setState({ searchValue: value, searchTerm: value })
    } else {
      await this.props.getDocumentsForUsers('homePage')
    }
    await this.props.getResourceTypes();
    await this.props.getLowAreas();
    const token = this.props.match && this.props.match.params.token;

    if (token && this.props.match.path === '/email_update/:token') {
      if (token) this.props.emailUpdate(token);
      this.setState({ modalEmail: true })
      this.props.history.push('/');
    }

    if (token && this.props.match.path === '/confirmation/:token') {
      const res = token && (await this.props.confirmUser(token));
      res &&
      res.payload &&
      res.payload.status === 200 &&
      this.onOpenModal('modalRegister', SuccessRegister, this.onCloseModal)
      // this.props.history.replace("/");
    }

    if (token && this.props.match.path === '/reset_pass/:token') {
      this.onOpenModal('modalReset', ResetPasswords, this.onCloseModal);
      this.props.saveResetToken(token)
      this.props.history.replace('/')
    }

    if (this.props.match.path === '/contributor_verification/:token') {
      const res = await this.props.contributorVerification(token);
      if (res && res.type === 'contributor_verification') {
        await this.setState(prevState => {
          return { success: !prevState.success }
        })
      }
      this.props.history.push('/')
    }

    if (this.props.match.path === '/user_confirmation/editor/:token') {
      const res = await this.props.editorVerification(token);
      if (res && res.type !== 'error') {
        this.setState(prevState => {
          return {
            editorModal: !prevState.editorModal,
            editorToken: this.props.match.params.token,
          }
        })
      }
      this.props.history.replace('/')
    }
    if (this.props.match.path === '/user_confirmation/lawyer/:token') {
      const res = await this.props.lawyerVerification(token);
      if (res && res.type !== 'error') {
        this.setState(prevState => {
          return {
            lawyerModal: !prevState.editorModal,
            lawyerToken: this.props.match.params.token,
          }
        });
        this.onOpenModal('modalSignUp', SignUp);
      } else {
        this.props.history.push('/');
      }
    }
    this.setState({ is_loading: false });
  }

  componentDidUpdate(prevProps, prevState){
    const {searchValue} = this.state;
    if(prevState.searchValue !== searchValue && !searchValue){
      this.searchResults(searchValue)
    }
    if(this.props.history.location.pathname.includes('/document/') && this.props.auth.isLoggedIn && prevProps.auth.isLoggedIn !== this.props.auth.isLoggedIn){
      this.props.history.go(0);
    }
  }


  hideResults = () => {
    this.setState({ showresults: false })
  };

  onOpenModal = (name, component) => {
    if (name === 'closeConfirm'){
      this.props.history.push('/');
    }

    this.setState({ [name]: true, component, editorModal: false, });
  };

  handleUnAutorizeClick = () => {
    this.onOpenModal('modalDocuments', SignIn, this.onCloseModal);
  };

  handleLoguot = async () => {
    const { clearDocuments, clearLowAreas, clearResourceTypes, removeToken, removeSubscription } = this.props;
    removeToken();
    removeSubscription();
    handleLoguot();
    Promise.all([
      clearDocuments(),
      clearLowAreas(),
      clearResourceTypes()
    ])
      .then( () => {
        this.props.getDocumentsForUsers('homePage');
        this.props.getResourceTypes();
        this.props.getLowAreas();
      });

    this.setState({ searchValue: '', showresults: false});
    this.props.history.push('/');
    const checkboxes = document.querySelectorAll('#filter input');
    checkboxes.forEach( checkbox => {
      checkbox.checked = false;
    } );

    await this.setState({
      query: {
        resources: [],
        lowAreas: []
      },
      queries: {
        resources: [],
        lowAreas: []
      },
      isLogedOut: !this.state.isLogedOut,
    });
  };

  onCloseModal = name => {
    const { location } = this.props.history;


    if (this.state.modalRegister) {
      name = 'modalRegister'
    }

    if (this.props.location !== '/') {
      this.setState({
        [name]: false,
        lawyerModal: false,
      })
    }
    this.setState({
      [name]: false,
      modalSignIn: false,
      modalSignUp: false,
      lawyerConfirm: false,
      modalReset: false,
    });

    if (!location.pathname.includes('/document')){
      this.props.history.push('/');
    }
  };

  onClosePopup = (type, email) => {
    if (type === 'email') {
      this.setState(prevState => {
        return { modalEmail: !prevState.modalEmail, success: false }
      })
    } else {
      this.setState(prevState => {
        return { success: !prevState.success }
      })
    }
  };

  onCloseEditorModal = () => {
    this.setState(prevState => {
      return { editorModal: !prevState.editorModal }
    })
  };


  onCloseEmailModal = () => {
    this.setState(prevState => {
      return { modalEmail: !prevState.modalEmail }
    })
  };

  handleQuery = async (query, type) => {
    await this.setState({
      ...this.state,
      queries: { ...this.state.queries, [type]: query }
    });

    let newQuery = [
      ...this.state.queries.resources,
      ...this.state.queries.lowAreas,
    ];


    if (this.state.searchValue){
      newQuery = [...newQuery, `search_term=${this.state.searchValue}`];
    }
    await this.props.clearDocuments();
    await this.props.getDocumentsForUsers('homePage', newQuery);
  };

  handleChange = async obj => {
    this.setState(obj);
  };

  searchResults = async value => {
    if (value.trim()) {
      const searchTerm = `search_term=${value}`;
      const newQuery = [
        ...this.state.queries.resources,
        ...this.state.queries.lowAreas,
        searchTerm
      ];
      await this.props.getDocumentsForUsers('homePage', newQuery);
      await this.setState({
        showresults: true,
        searchTerm
      })
    } else {
      await this.setState({
        searchTerm: '',
      });
      const newQuery = [
        ...this.state.queries.resources,
        ...this.state.queries.lowAreas,
      ];
      await this.props.clearDocuments();
      await this.props.getDocumentsForUsers('homePage', newQuery)
    }
  };

  documentsPaginate = async (resourcesCount, resources, docPage) => {
    const searchTerm = this.state.searchTerm;
    const newquery = [
      ...this.state.queries.resources,
      ...this.state.queries.lowAreas,
      searchTerm ? searchTerm : ''
    ];
    if (resources.length < resourcesCount) {
      await this.props.getDocumentsForUsers('homePage', newquery, docPage)
    }
  };

  handleClick = name => {
    this.setState(prevState => ({ [name]: !prevState[name] }))
  };

  getDocumentId = documentId => {
    this.setState({ documentId })
  };

  checkboxHandler = ( type, resources ) => {
    this[type] = resources;
  };

  render () {
    let token, role;
    if (localStorage.getItem('auth')) {
      token = localStorage.getItem('auth');
      role = localStorage.getItem('role')
    } else if (sessionStorage.getItem('auth')) {
      token = sessionStorage.getItem('auth');
      role = sessionStorage.getItem('role')
    } else if (this.props.user && this.props.user.token) {
      token = this.props.user.token
    }

    const editorConfirmData =
      this.props.editorConfirmData &&
      this.props.editorConfirmData.data &&
      this.props.editorConfirmData.data;

    const lawyerConfirmData =
      this.props.lawyerConfirmData &&
      this.props.lawyerConfirmData.data;

    const {
      success,
      showresults,
      searchValue,
      editorModal,
      editorConfirm,
      isLawyerConfirm,
      editorToken,
      modalEmail,
      documentId,
      isLogedOut,
      component,
      queries,
      is_loading,
    } = this.state;


    const query = {
      resources: [...this.props.resources.allResources],
      lowAreas: [...this.props.lowAreas.allLowAreas],
      resourcesCount: this.props.resources.count,
      lawCount: this.props.lowAreas.count,
      lowNext: this.props.lowAreas.next,
      resourcesNext: this.props.resources.next
    };

    const {
      resources,
      lowAreas,
      resourcesCount,
      lawCount,
      lowNext,
      resourcesNext
    } = query;

    const documents = [...this.props.documents.allDocuments];
    const documentsCount = this.props.documents.count;
    const documentsNext = this.props.documents.next;
    const docIndex = documentsNext && documentsNext.lastIndexOf('=');
    const docPage = documentsNext && documentsNext.substr(docIndex + 1);
    const lowIndex = lowNext && lowNext.indexOf('=');
    const lowPage = lowIndex && lowNext.substr(lowIndex + 1);
    const resourcesIndex = resourcesNext && resourcesNext.indexOf('=');
    const resourcesPage = resourcesIndex && resourcesNext.substr(resourcesIndex + 1);
    const { id } = this.props.match.params;

    return (
      <div>
        <Header
          role={role}
          token={token}
          searchResults={this.searchResults}
          open={this.state.open}
          modalSignIn={this.state.modalSignIn}
          modalSignUp={this.state.modalSignUp}
          editorModal={this.state.editorModal}
          lawyerModal={this.state.lawyerModal}
          lawyerConfirm={this.state.isLawyerConfirm}
          lawyerToken={this.state.lawyerToken}
          handleClick={this.handleClick}
          modalReset={this.state.modalReset}
          modalRegister={this.state.modalRegister}
          component={this.state.component}
          onOpenModal={this.onOpenModal}
          handleLoguot={this.handleLoguot}
          onCloseModal={this.onCloseModal}
          onHandleChange={this.handleChange}
          showresults={showresults}
          searchValue={searchValue}
          hideResults={this.hideResults}
          showSearch={true}
        />

        <section className="main-content search-results">
          <div className="container">
            <div className="items flex justify-between">
              {
                !id && <div className="filter" id={'filter'}>
                  <Modal open={editorModal} onClose={this.onCloseEditorModal}>
                    <ConfirmationEditor
                      editorConfirm={editorConfirm}
                      editorConfirmData={editorConfirmData}
                      handleClick={this.handleClick}
                      onOpenModal={this.onOpenModal}
                      editorToken={editorToken}
                    />
                  </Modal>
                  {/* In case of search mode add the "search-view" class to the below included div */}
                  <div className="card br-corner-xs">
                    {resources && lowAreas && (
                      <Resources
                        query={this.resources}
                        resources={resources}
                        type="resources"
                        method={this.props.getResourceTypes}
                        resourcesCount={resourcesCount}
                        showresults={showresults}
                        dataLength={resourcesCount}
                        page={resourcesPage}
                        isLogedOut={isLogedOut}
                        handleQuery={this.handleQuery}
                        checkboxHandler={this.checkboxHandler}
                      />
                    )}
                    {resources && lowAreas && (
                      <Resources
                        query={this.lowAreas}
                        resources={lowAreas}
                        method={this.props.getLowAreas}
                        resourcesCount={lawCount}
                        isLogedOut={isLogedOut}
                        showresults={showresults}
                        dataLength={lawCount}
                        page={lowPage}
                        type="lowAreas"
                        handleQuery={this.handleQuery}
                        checkboxHandler={this.checkboxHandler}
                      />
                    )}
                  </div>
                </div>
              }
              {success && (
                <Modal open={this.state.success} onClose={this.onClosePopup}>
                  <SuccessPopup
                    text={`Thanks for Signing up to Form as a Contributing Lawyer. Now your profile will be reviewed by the Administrator for the approval`}
                    onClose={this.onClosePopup}
                    icon="envelop"
                    title={true}
                  />
                </Modal>
              )}

              {modalEmail && (
                <Modal open={modalEmail} onClose={this.onCloseEmailModal}>
                  <SuccessPopup
                    text={'Your email address has been changed successfully!'}
                    onClose={this.onCloseEmailModal}
                    icon="lock"
                    title={true}
                  />
                </Modal>
              )}

              {documents.length? (
                <Documents
                  getDocument={this.getDocumentId}
                  documents={documents}
                  searchResults={this.searchResults}
                  hasMore={this.state.hasMore}
                  loadFunc={() =>this.documentsPaginate(documentsCount, documents, docPage)}
                  dataLength={documents}
                  token={token}
                  onOpenModal={this.handleUnAutorizeClick}
                  open={this.state.modalDocuments}
                  component={this.state.component}
                  modalBody={modalBody}
                  onCloseModal={() => this.onCloseModal('modalDocuments')}
                  onHandleChange={this.handleChange}
                  showresults={showresults}
                  searchValue={searchValue}
                />
              ): (
                !is_loading && <p className="placeholder-text">There are no results to display</p>
              )}
              {
               id &&
               <Document
                 id={id}
                 onOpenModal={this.onOpenModal}
               />
              }

            </div>
          </div>
        </section>
        <Footer/>
      </div>
    )
  }

}

function mapStateToProps (state) {
  return {
    error: state,
    user: state.auth.user,
    documents: state.documents.documents,
    resources: state.documents.resources,
    lowAreas: state.documents.lowAreas,
    editorConfirmData: state.auth.editorConfirmData,
    contributorConfirmData: state.auth.contributorConfirmData,
    auth: state.auth,
  }
}

export default connect(
  mapStateToProps,
  {
    emailUpdate,
    clearDocuments,
    clearLowAreas,
    clearResourceTypes,
    getDocumentsForUsers,
    getResourceTypes,
    getLowAreas,
    removeToken,
    confirmUser,
    saveResetToken,
    contributorVerification,
    editorVerification,
    lawyerVerification,
    removeSubscription,
  }
)(withRouter(Homepage))
