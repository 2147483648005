import React, {Component} from 'react';
import Download from '../../../assets/img/download.png'
import {Field, Formik} from 'formik'
import Modal from 'react-responsive-modal'

export default class DocumentDownload extends Component {

  render() {
    const {
      open,
      onClose,
      downloadHandler,
      formFieldChangeHandler,
    } = this.props;

      return (
      <Modal
        onClose={onClose}
        open={open}
      >
        <div className="popup-content">
          <h3 className="popup-title">DOWNLOAD THIS DOCUMENT</h3>
          <div className="popup-img">
            <img src={Download} alt="Download_image"/>
          </div>
          <Formik
            onSubmit={downloadHandler}
            render={props => (
              <form
                className="mt-8"
                onSubmit={props.handleSubmit}
                onChange={formFieldChangeHandler}
              >
                <p className="text-xs-left color-black-60 mb-2">Select an
                  option</p>
                <ul className="flex align-center">
                  <li>
                    <label className="radio-button circle mr-3">
                      <Field
                        defaultChecked={true}
                        type="radio"
                        name="chooseOption"
                        className="checkbox-control"
                        value={false}
                      />
                      <span className="radio-icon">{""}</span>
                      <span className="radio-label">Document</span>
                    </label>
                  </li>
                  <li>
                    <label className="radio-button circle mr-3">
                      <Field
                        value={true}
                        type="radio"
                        name="chooseOption"
                        className="checkbox-control"
                      />
                      <span className="radio-icon">{""}</span>
                      <span
                        className="radio-label">Document & Drafting Tips</span>
                    </label>
                  </li>
                </ul>
                <p className="text-xs-left color-black-60 mt-5 mb-2">Select
                  format</p>
                <ul className="flex align-center">
                  <li>
                    <label className="radio-button circle mr-3">
                      <Field
                        value={"pdf"}
                        type="radio"
                        className="checkbox-control"
                        name="chooseFormat"
                      />
                      <span className="radio-icon">{""}</span>
                      <span className="radio-label">PDF</span>
                    </label>
                  </li>
                  <li>
                    <label className="radio-button circle mr-3">
                      <Field
                        defaultChecked={true}
                        value={"word"}
                        type={"radio"}
                        className={"checkbox-control"}
                        name={"chooseFormat"}
                      />
                      <span className="radio-icon">{""}</span>
                      <span className="radio-label">Microsoft Word</span>
                    </label>
                  </li>
                </ul>
                <div className="mt-10 text-xs-right">
                  <button
                    type={"button"}
                    onClick={onClose}
                    className="btn link primary h-lg w-lg"
                  >Cancel
                  </button>
                  <button
                    className="btn filled primary br-round h-lg shadow w-lg"
                    type={'submit'}
                  >
                    Download
                  </button>
                </div>
              </form>
            )}
          />
        </div>
      </Modal>
    );
  }


}