import React, {Component} from 'react';
import {withRouter} from 'react-router';
import { roleChecker } from '../../helpers/improve';

class Footer extends Component {

    render() {
      let isHomePage = false;
      const role = roleChecker();
      
      return (
            /* Footer start */
            <footer className={`${role && role !== 'user' ? 'dashboard': ''}`}>
                <div className="container">
                    <p className="color-white font-xs font-secondary text-xs-center">Copyright © {(new Date()).getFullYear()} Form.Legal LLC, a subsidiary of John Dorsey PLLC. All rights reserved.</p>
                </div>
            </footer>
            /* Footer end */
        )
    }
}

export default withRouter(Footer);
