import { privateApi, publicApi } from "./../../http/axios";

import {
  GET_PLANS,
  GET_PLAN,
  GET_PLAN_BENEFITS,
  GET_USER_CARD,
  ADD_USER_CARD,
  DELETE_USER_CARD,
  SUBSCRIBE_SUCCEED,
  SUBSCRIBE_FAILURE,
  GET_SUBSCRIPTION,
  REMOVE_SUBSCRIPTION,
} from "./types";

export const getPlans = () => dispatch => {
  const url = "/plans/";
  return publicApi.get(url).then(({ data }) => {
    const results = Object.keys(data.results).length ? data.results : [];
    return dispatch({
      type: GET_PLANS,
      payload: { data: results }
    });
  });
};

export const getPlan = id => dispatch => {
  const url = `/plans/${id}/`;
  return privateApi.get(url).then(({ data }) => {
    return dispatch({
      type: GET_PLAN,
      payload: { data }
    });
  });
};

export const getBenefits = () => dispatch => {
  const url = "/plan_benefits/";
  return publicApi.get(url).then(({ data }) => {
    return dispatch({
      type: GET_PLAN_BENEFITS,
      payload: { data: data.result }
    });
  });
};

export const getUserCard = () => dispatch => {
  const url = "/user_credit_card/";
  return privateApi.get(url).then(({ data }) => {
    return dispatch({
      type: GET_USER_CARD,
      payload: { data: data.results }
    });
  });
};

export const addUserCard = data => dispatch => {
  const url = "/credit_cards/";
  return privateApi.post(url, data).then(data => {
    if (data.status === 400) {
      const errorMessage = data.data.length && data.data[0];
      const index = errorMessage.indexOf(": ");
      const message = errorMessage.slice(index + 2);
      return {
        error: true,
        message
      };
    }
    return dispatch({
      type: ADD_USER_CARD,
      payload: { data: data.data.results }
    });
  });
};

export const deleteUserCard = data => dispatch => {
  const url = "/credit_cards/detach/";
  return privateApi
    .post(url, data)
    .then(() => dispatch({ type: DELETE_USER_CARD }));
};

export const setSubscription = data => dispatch => {
  const url = "/subscriptions/";
  return privateApi
    .post(url, data)
    .then(res => {
      dispatch({
        type: SUBSCRIBE_SUCCEED,
        payload: { success: true }
      });
      return res;
    })
    .catch(({ response }) => {
      dispatch({
        type: SUBSCRIBE_FAILURE,
        payload: {
          success: false,
          error: true,
          status: response.status,
          message: response.data.message
        }
      });
      return response;
    });
};

export const getSubscription = () => dispatch => {
  const url = "/subscriptions/";
  return privateApi.get(url).then(({ data }) =>
    dispatch({
      type: GET_SUBSCRIPTION,
      payload: { data: data.results[0] }
    })
  );
};

export const cancelSubscription = id => dispatch => {
  const url = `/subscriptions/${id}/`;
  return privateApi.delete(url).then(data => {
    return data;
  });
};

export const getAccessDate = id => dispatch => {
  const url = `/subscriptions/canceled_message/${id}/`;
  return privateApi.get(url).then(({ data }) => {
    return data;
  });
};

export const removeSubscription = () => dispatch => {
  return dispatch({ type: REMOVE_SUBSCRIPTION });
};
