import React from 'react';

export default function IAgreeWith({ values, handleChange, handleBlur, aboutFiles }) {
    let terms_of_use = {};
    let privacy_policy = {};
    let baseUrl = 'https://api-docpool.codebnb.me/about/download/';
    if (aboutFiles){
        terms_of_use.terms_of_use_download_url = aboutFiles.terms_of_use && `${baseUrl + aboutFiles.terms_of_use.id}/`;
        privacy_policy.privacy_policy_download_url = aboutFiles.privacy_policy && `${baseUrl + aboutFiles.privacy_policy.id}/`;

        terms_of_use.content =  ' Terms of Service ';
        privacy_policy.content =  ' Privacy Policy ';
    }

    return (
        <label className="checkbox round mt-4 mb-6 checkbox-no-hover">
            <input
                onChange={handleChange}
                onBlur={handleBlur}
                type="checkbox"
                className="checkbox-control"
                name="searchable"
                checked={values.searchable}
            />
            <span className="check-icon"> {""} </span>
            <span className="checkbox-label">
                I agree to the
                {
                    terms_of_use.terms_of_use_download_url ? (
                        <a href={terms_of_use.terms_of_use_download_url} className="color-primary fw-600" >
                            { terms_of_use.content }
                        </a>
                    ):(
                        terms_of_use.content
                    )
                }
                and
                {
                    privacy_policy.privacy_policy_download_url ? (
                        <a href={privacy_policy.privacy_policy_download_url} className="color-primary fw-600" >
                            { privacy_policy.content }
                        </a>
                    ):(
                        privacy_policy.content
                    )
                }
              </span>
        </label>
    );
};