export const commandToClipboard = (e, type, editorRef) => {
  const selection = window.getSelection();
  const range = document.createRange();
  const relatedTargetParent = window.getSelection().baseNode && window.getSelection().baseNode.parentNode;
  const editor = document.getElementById('editor');
  if (!selection.focusNode || selection.type === 'None' || !editor.contains(relatedTargetParent) || !relatedTargetParent) {
    return;
  }
  const button = e.currentTarget;
  if (selection.type === 'Caret') {
    const relatedTarget = window.getSelection().focusNode;
    const lengthOfChild = selection.focusOffset;
    range.setStart(relatedTarget, lengthOfChild);
    range.setEnd(relatedTarget, lengthOfChild);
    selection.removeAllRanges();
    selection.addRange(range);
  }
  document.execCommand(type, false, type === 'createLink' ? `${selection}` : '');
};

export const toolbarStyle = (that) => {
  const buttons = document.querySelectorAll('.text-format ');

  const stage = stageOfExecCommand();
  buttons.forEach(button => {
    if (stage[button.id]) {
      if (!button['className'].includes('active')) {
        button['className'] += ' active';
      }
    } else {
      if (button['className'].includes('active')) {
        button['className'] = button['className'].replace('active', '');
      }
    }
  });
};

export const initializeEditorFunctionality = (editor, button, editorChangeEvent) => {
  // if (!editor || !button) return;
  if (editorChangeEvent
    && (editorChangeEvent.inputType === 'insertLineBreak'
      || (editorChangeEvent.inputType === 'insertText' && editorChangeEvent.data === null)
      || (editorChangeEvent.inputType === 'historyUndo' || editorChangeEvent.inputType === 'historyRedo')
    )
  ) {
    if (button){
      button.style.display = 'none';                         /** case of CONTROL + ENTER command*/
    }
  }


  /** Initialize paragraphs functionality*/

  const paragraphsOfEditor = editor.querySelectorAll("div[id='editor']  p, h1, h2, h3, h4, h5, h6"); /*div,*/
  let initialDataId = 0;
  let initialId = 0;
  paragraphsOfEditor.forEach(paragraph => {
    const isInDraftingNote = paragraph.closest('.draftingNote');
    if (isInDraftingNote){ return; }

    paragraph.setAttribute('data-id', ++initialDataId);
    if (!paragraph.className.includes('edit-doc-section')){
      paragraph.className += ' edit-doc-section';                         /**  deletable in Css*/
    }
  
    if(!paragraph.innerText.trim()){
      if(!paragraph.className.includes('empty')){
        paragraph.className += ' empty';
      }
    } else if (paragraph.className.includes('empty')){
      let slicedClassName = paragraph.className.split(' ').filter(item => item !== 'empty').join(' ');
      paragraph.className = slicedClassName;
    }
    
    if (
      paragraph.nextSibling
      && paragraph.nextSibling.className
      && paragraph.nextSibling.className.includes('draftingNote')
    ) {
      paragraph.id = ++initialId;
      if (!paragraph.className.includes('annotatedParagraph')){
        paragraph.className += ' annotatedParagraph';
      }
      paragraph.nextSibling.setAttribute('data-id', initialId);
      paragraph.onmouseenter = null;
      paragraph.onmouseout = null;
    } else {
      paragraph.removeAttribute('id');
      _setMouseEnter(paragraph, button, initialId);
      setMouseOut(paragraph, button);
      setMouseMove(paragraph, button, initialId);
      paragraph.removeAttribute('id');
      if (paragraph.className.includes('annotatedParagraph')){
        paragraph.className = paragraph.className.replace('annotatedParagraph', '');
      }
    }
    if (paragraph.childNodes && paragraph.childNodes.length ){
      let isExist = false;
      paragraph.childNodes.forEach( node => {
        if( node.tagName === 'OL' || node.tagName === 'UL'){
          return isExist = true;
        }
      });
      if (isExist){
        paragraph.removeAttribute('class');
        paragraph.removeAttribute('id');
        paragraph.removeAttribute('data-id');
        paragraph.onmouseenter = null;
        paragraph.onmousemove = null;
        paragraph.onmouseout = null;
      }
    }
  });

  /** Add empty paragraph to end when last element of editor is drafting note (for work delete annotation button )*/
  if (editor.lastElementChild && editor.lastElementChild.tagName === 'DIV') {
    const emptyParagraph = document.createElement('p');
    emptyParagraph.className = 'edit-doc-section';
    emptyParagraph.innerHTML = '&nbsp;';
    editor.appendChild(emptyParagraph)
  }
  /** End paragraphs initialization */

};

/** Initialize plusButton functionality */

export const addButtonInitialization = (editor, button) => {
  button.onmouseout = e => {
    if (e.relatedTarget && (
      e.relatedTarget.tagName !== 'P'
      // || e.relatedTarget.tagName !== 'div'
      || e.relatedTarget.tagName !== 'H1'
      || e.relatedTarget.tagName !== 'H2'
      || e.relatedTarget.tagName !== 'H3'
      || e.relatedTarget.tagName !== 'H4'
      || e.relatedTarget.tagName !== 'H5'
      || e.relatedTarget.tagName !== 'H6'
    )) {
      button.style.display = 'none';
    }

    const hoveredParagraph = editor.querySelector('.hovered');
    hoveredParagraph.className = hoveredParagraph.className.replace('hovered', '');
  };

  button.onclick = e => {
    const id = e.target.dataset.id;
    const annotatedParagraph = document.querySelector(`[data-id="${id}"]`);
    /**  p`[data-id="${id}"]`   */
    const textLength = +annotatedParagraph.childNodes.length;
    const div = `<div data-id=${id} class="draftingNote expanded">${annotation}</div>`;
    const selection = window.getSelection();
    annotatedParagraph.setAttribute('id', `${id}`);
    button.style.display = 'none';
    selection.collapse(annotatedParagraph, textLength);
    document.execCommand('insertHTML', false, div);

    if (!annotatedParagraph.nextElementSibling) {
      /** when dom not has been rendered. */
      e.preventDefault();
      return
    }
    initializeAnnotationFunctionality(annotatedParagraph)
  }
};

/** End button initialization */

export const addDocumentDataToEditor = (data, editor) => {
  const annotatedParagraphs = Array.from(editor.querySelectorAll('.annotatedParagraph'));
  const PrintableDocument = document.getElementById('printableDocument');
  const printableParagraphs = document.querySelectorAll('.annotatedParagraph');
  const initializeStage = true;
  annotatedParagraphs.forEach((paragraph, index) => {
    const currentNote = data[index];
    if (!currentNote) return;
    const draftingNote = document.createElement('div');
    const textLength = +paragraph.childNodes.length;
    draftingNote.innerHTML = annotation;
    draftingNote.className = 'draftingNote';
    draftingNote.firstElementChild.firstElementChild.value = currentNote.title;
    draftingNote.firstElementChild.lastElementChild.innerHTML = currentNote.content;
    paragraph.parentNode.insertBefore(draftingNote, paragraph.nextSibling);
    initializeAnnotationFunctionality(paragraph, textLength, initializeStage);
  })
};

export const stageOfExecCommand = () => {
  const types = ['bold', 'strikeThrough', 'italic', 'underline'];
  const stateOfCommand = {};
  types.forEach(type => {
    const state = document.queryCommandState(type);
    stateOfCommand[type] = state;
  });
  return stateOfCommand;
};


export const annotation = ' <div  contentEditable="false"' +
  ' >\n' +
  `     <input class="note-title" contenteditable="true">\n` +
  '     <button class="btn-manage btn-delete">delete</button>\n' +
  '     <button class="btn-manage btn-expand"></button>\n' +
  '     <div contenteditable="false"><span contentEditable="true" area-readonly="true" unselectable="ON" ' +
  '      suppressContentEditableWarning={true}></span></div>' +
  `     <div class="note-desc" contentEditable="true"></div>\n` +
  '</div>'

export const initializeAnnotationFunctionality = (annotatedParagraph, stage) => {
  let currentAnnotation = annotatedParagraph.nextElementSibling;
  const showDraft = currentAnnotation.querySelector(`div button[class="btn-manage btn-expand"]`);
  const deleteDraft = currentAnnotation.querySelector(`div button[class="btn-manage btn-delete"]`);
  const draftingTitle = currentAnnotation.querySelector(`div input`);
  const draftingContent = currentAnnotation.querySelector(`div[class="note-desc"]`);

  if (!draftingContent || !deleteDraft || !showDraft){
    return;
  }

  draftingContent.onkeypress = e => {

    /** make it only in Li tag     * */
    if (e.key === 'Enter') {
      e.preventDefault();
      document.execCommand("insertLineBreak");
    }
  };

  deleteDraft.onclick = (e) => {
    const index = annotatedParagraph.className.indexOf('annotatedParagraph');
    const range = document.createRange();
    range.selectNode(currentAnnotation);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    document.execCommand('delete', false, null);
    document.execCommand('insertParagraph', true, '');
    if (index !== -1){
      annotatedParagraph.className = annotatedParagraph.className.substring(0, index - 1);
    }
  };

  showDraft.onclick = () => {
    if (currentAnnotation.className.includes('expanded')) {
      currentAnnotation.className = 'draftingNote'
    } else if (!currentAnnotation.className.includes('expanded')) {
      currentAnnotation.className += ' expanded'
    }
  }
};

export const addParagraphInLists = editor => {
  const lists = editor.querySelectorAll('li');
  const paragraphs = editor.querySelectorAll('p');
  const p = document.createElement('p');
  lists.forEach( li => {
    p.append(li.innerHTML);
    li.innerHTML = '';
    li.appendChild(p);
  });
};

export const getPositionOfElement = element => {
  const rect = element.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
    height: rect.height,
    width: rect.width,
  }
};

export const buttonPositionChangeHandler = (paragraph, button, initialId, paragraphMouseEnterEvent) => {
  const paragraphPosition = getPositionOfElement(paragraph);
  const paragraphIsEmpty = _paragraphIsEmpty(paragraph);
  const {height, width, top, left} = paragraphPosition;


  if (!paragraphIsEmpty) {
    const id = paragraphMouseEnterEvent.target.dataset.id;
    button.style.display = 'block';
    button.style.top = top + height - button.offsetHeight / 2 - 7 + 'px';
    button.style.left = left + width - button.offsetWidth / 2 + 'px';
    button.setAttribute('data-id', id);
    button.setAttribute('data-lastNoteDataId', initialId + 1);
  } else {
    return null;
  }
};


const _paragraphIsEmpty = element => {
  const content = element.innerText.trim();
  if (content === '') {
    return true;
  }
};

const _setMouseEnter = (paragraph, button, initialId) => {
  paragraph.onmouseenter = e => {
    buttonPositionChangeHandler(paragraph, button, initialId, e);
  }
};
export const setMouseMove = (paragraph, button, initialId) => {
  paragraph.onmousemove = e => {
    if (paragraph.innerText.trim()) {
      buttonPositionChangeHandler(paragraph, button, initialId, e);
      paragraph.onmousemove = null;
    } else {
      button.style.display = 'none';
    }
  }
};

export const setMouseOut = (paragraph, button) => {
  paragraph.onmouseout = e => {
    if(e.relatedTarget && e.relatedTarget.tagName === 'BUTTON'){
      paragraph.className += ' hovered';
    }

    if (
      !e.relatedTarget
      || e.relatedTarget
      && e.relatedTarget.tagName !== 'BUTTON'
      && e.relatedTarget !== paragraph
      && !paragraph.contains(e.relatedTarget)
    ) {
      button.style.display = 'none';
    }
  }
};


/**
 * Create new annotation structure to be able document printable.
 * */
export const annotationStyleChanger = function(title, content) {
  const annotation = `<p class='note-title'>Drafting Tip: ${title? title: '' }</p>
                          <div class='note-content'> ${content} </div> 
                         `;
  return annotation;
};


export const makeDocumentPrintable = (mainDocument, printableDocument, styleTag, mode) => {
  
  
  const annotations = mainDocument.querySelectorAll('.draftingNote');
  let  printableNotes = printableDocument.querySelectorAll('.draftingNote');
  if (styleTag){
    document.head.insertBefore(styleTag, document.head.firstElementChild);
  }

  printableNotes.forEach( (note, noteIndex) => {
    annotations.forEach( (annotation, annotationIndex) => {

      if (noteIndex === annotationIndex){
        const title = mode === 'edit' ? annotation.querySelector('.note-title').value: annotation.querySelector('.note-title').innerText;
        const content = annotation.querySelector('.note-desc').innerText;
        const newNote = annotationStyleChanger(title, content);
        const newElement = document.createElement('div');

        newElement.innerHTML = newNote;
        newElement.className = 'newDraftingNote';
        note.parentNode.insertBefore(newElement, note);
        note.remove();
      }
    });
  });
};


/**
 * @description Doing some cleaning work that improve document styles when initialize.
 * @param editableDocument - Current editing document.
 */
export const cleanDocument = editableDocument => {
  const style = editableDocument.querySelector('style');
  if (!style) return;
  // style.innerHTML = style.innerHTML.replace('underline none underline', 'underline');
};


