import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Modal from 'react-responsive-modal'
import PerfectScrollbar from 'react-perfect-scrollbar'

class Documents extends Component {

  state = {
    open: false,
    showDocument: false,
    documentId: null,
  }

  onCloseModal = () => {
    this.setState({ open: false })
  };

  handleClick = (e, id) => {
    if (e.target.tagName === 'A'){
      return e.target.click();
    }
    this.setState( { showDocument: true });
    this.props.history.push(`/document/${id}`);
  }

  renderDocuments = documents => {
    return documents.map(data => {
      return (
        <Fragment key={data.id}>
          <div
            className="card br-corner file"
            onClick={(e) => this.handleClick(e, data.id)}
          >            
            <div className="file-info">
              <i className="icon-doc"/>
              <div className="details">
                <h4 className="title">{data.name}</h4>
                <p className="desc">
                  {
                    data.url_imported_by ? (
                      <>
                        By <a
                        href={`${data.url_imported_by}`}
                        target='_blank'
                      >{data.imported_by}</a>
                      </>
                    ) : (
                      <>
                        By {data.imported_by}
                      </>
                    )
                  }
                  {
                    ' '
                  }
                  {
                    data.url_imported_with ? (
                      <>
                        and <a
                        href={`${data.url_imported_with}`}
                        target='_blank'
                      > {data.imported_with}</a>
                      </>
                    ) : (
                      <>
                        {
                          data.imported_with && (<>
                            and {data.imported_with}
                          </>)
                        }
                      </>
                    )
                  }
                  {
                    '   '
                  }
                  {
                    data.url_organization_imported_with ? (
                      <>
                        of <a
                        href={`${data.url_organization_imported_with}`}
                        target='_blank'
                      >{data.organization_imported_with}</a>
                      </>
                    ) : (
                      <>
                        {
                          data.organization_imported_with &&
                          <>
                            of {data.organization_imported_with}
                          </>
                        }
                      </>
                    )
                  }
                </p>
              </div>
            </div>

            { data.is_bought &&
            <p className="paid-document">
              <span className="dot" />
              <span className="tooltip-text">You have full access to this document</span>
            </p>
            }
            <i className="icon-arrow-right" />
          </div>
          {/* change implementation */}
          <Modal open={this.state.open} onClose={this.onCloseModal}>
            <div className="popup-content">
              <h3 className="popup-title">Document</h3>
              Document will be soon
            </div>
          </Modal>

          {this.props.component && (
            <Modal
              open={this.props.open}
              center
              onClose={this.props.onCloseModal}
            >
              {this.props.open &&
              this.props.modalBody(
                this.props.component,
                this.props.onCloseModal
              )}
            </Modal>
          )}
        </Fragment>
      )
    })
  };

  render () {
    const { documents } = this.props;
    const { match: { url } } = this.props;

    if (url.startsWith('/document')) {
      return null
    }

    return (
      <div className="files">
        {documents && (
          <PerfectScrollbar style={{ height: '650px' }} onYReachEnd={() => this.props.loadFunc()} >
            { this.renderDocuments(documents) }
          </PerfectScrollbar>
        )}
      </div>
    )
  }
}

export default withRouter(Documents)