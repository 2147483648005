import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import Homepage from "./components/Homepage/homePage/homepage";
import searchResults from "./components/Homepage/searchResults";
import ForgotPassword from "./components/Homepage/forgot-password/forgotPassword";
import Admin from "./components/admin";
import Lawyer from './components/lawyer';
import User from "./components/user/";
import Editor from "./components/editor/";
import Pricing from "./components/Homepage/pricing/pricing";
import OrderDetails from "./components/user/orderDetails";
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import NoMatch from './helpers/NoMatch.js';
import windowScroll from './helpers/document.js'
import requireAuth from "./helpers/requireAuth";

class App extends React.Component {

  componentDidMount () {
    windowScroll();
  }


  render() {
    return (
      <>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/document/:id" component={Homepage} />
          <Route exact path="/confirmation/:token" component={Homepage} />
          <Route exact path="/reset_pass/:token" component={Homepage} />
          <Route exact path="/contributor_verification/:token" component={Homepage} />
          <Route exact path="/user_confirmation/lawyer/:token" component={Homepage} />
          <Route exact path="/user_confirmation/editor/:token" component={Homepage} />
          <Route exact path="/email_update/:token" component={Homepage} />
          <Route exact path="/linkedin" component={LinkedInPopUp} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/search-results" component={searchResults} />
          <Route path='/admin' component={Admin} />
          <Route path='/lawyer' component={Lawyer}/>
          <Route path='/editor' component={Editor}/>
          <Route path='/user/profile' component={User}/>
          <Route path='/user/order' component={OrderDetails}/>
          <Route path='/pricing' component={Pricing}/>
          <Route component={NoMatch} />
        </Switch>
      </>
    );
  }
}

export default withRouter(requireAuth(App));
