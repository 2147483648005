import React from 'react';
import {Link} from "react-router-dom";

const documents = props => {
    const { path } = props;

    return (
        <>
            <ul className="nav">
                <li className="title">Filters</li>
                <li>
                    <Link
                        to="/admin/resource-types"
                        className={`${path === "/admin/resource-types" ? "active" : ""}`}
                    >
                        Document types
                    </Link>
                </li>
                <li>
                    <Link
                        to="/admin/area-of-low"
                        className={`${path === "/admin/area-of-low" ? "active" : ""}`}
                    >
                        Legal area
                    </Link>
                </li>
            </ul>
            <ul className="nav">
                <li className="title">ROLES</li>
                <li>
                    <Link
                        to="/admin/editors"
                        className={`${path === "/admin/editors" ? "active" : ""}`}
                    >
                        Editors
                    </Link>
                </li>
                <li>
                    <Link
                        to="/admin/users"
                        className={`${path === "/admin/users" ? "active" : ""}`}
                    >Users</Link>
                </li>
                <li>
                    <Link
                        to="/admin/contribute-lawyers"
                        className={`${path === "/admin/contribute-lawyers" ? "active" : ""}`}
                    >
                        Contributing lawyers
                    </Link>
                </li>
            </ul>
            <ul className="nav">
                <li className="title">SETTINGS</li>
                <li>
                    <Link
                        to="/admin/about"
                        className={`${path === "/admin/about" ? "active" : ""}`}
                    >About</Link>
                </li>
                <li>
                    <Link
                        to="/admin/pricing"
                        className={`${path === "/admin/pricing" ? "active" : ""}`}
                    >Pricing</Link>
                </li>
            </ul>
        </>
    );
};

export default documents;
