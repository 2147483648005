import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {withFormik, Form, Field} from "formik";
import * as Yup from "yup";
import {privateApi} from "../../../http/axios";
import { withRouter } from 'react-router';

import {LinkedIn} from 'react-linkedin-login-oauth2';
import {GoogleLogin} from 'react-google-login';
import {signinUser, signupUser, signUpWithGoogle, signUpWithLinkedIn, loggedIn } from "./../../../store/actions/auth";
import ForgotPassword from "../forgot-password/forgotPassword";
import SignUp from "../register/signUp";
import SuccessPopup from "../successPopup";
import google from "../../../assets/img/google.svg";
import linkedin from "../../../assets/img/linkedin.svg";
import {socialLogIn} from "../../../helpers/improve";


class Login extends Component {
  state = {
    component: "login"
  };

  handleComponent = (e, component) => {
    e.preventDefault();
    this.setState({component});
  };

    signInWithGoogleSuccess = async res => {
      const response = await this.props.signUpWithGoogle(res.accessToken);

      if (response.type === 'sign_in_user') {
            const { token, id } = response.payload.data;
            socialLogIn( token, id, '1');
            this.props.loggedIn();
            this.props.onClose('modalSignIn');
        }else {
          this.props.setErrors({ ...this.props.errors,  signUp: 'The provided email already exists.'});
        }
    };

    signWithGoogleFailure = err => {
        console.log(err);
    };

    signInWithLinkedInSuccess = async res => {
      const response = await this.props.signUpWithLinkedIn(res.code);
      if (response.type === 'sign_in_user') {
        const { token, id } = response.payload.data;
        socialLogIn(token, id, '1');
        this.props.loggedIn();
        this.props.onClose('modalSignUp');
      }else {
          this.props.setErrors({ ...this.props.errors,  signUp: 'The provided email already exists.'});
        }
    };

    signInWithLinkedInFailure = async res => {
        console.log(res);
    };

  render() {
    const {values, errors, touched, handleChange, handleBlur} = this.props;
    const {component} = this.state;
  
    return (
      /* Login popup start */
      <Fragment>
        {
          component === "login"
          && !values.notVerifyContributer
          && <div className="popup-content">
            <h3 className="popup-title">Login</h3>
            <Form>
              <div
                className={`text-field br-corner ${touched.email &&
                errors.email &&
                "invalid"}`}
              >
                <Field
                  type="text"
                  placeholder="Email address*"
                  value={values.email}
                  name="email"
                />
                {touched.email && errors.email && (
                  <p className="error-msg">{errors.email}</p>
                )}
              </div>
              <div
                className={`text-field br-corner ${touched.password &&
                errors.password &&
                "invalid"}`}
              >
                <Field
                  type="password"
                  placeholder="Password*"
                  name="password"
                  value={values.password}
                />
                {touched.password && errors.password && (
                  <p className="error-msg">{errors.password}</p>
                )}
              </div>
              <div className="flex justify-between align-center mb-7">
                <label className="checkbox round">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="checkbox"
                    className="checkbox-control"
                    name="rememberme"
                    checked={values.searchable}
                  />
                  <span className="check-icon">{""}</span>
                  <span className="checkbox-label">Remember me</span>
                </label>
                <a
                  href="/"
                  onClick={e => this.handleComponent(e, "forgotPassword")}
                  className="color-primary fw-600"
                >
                  Forgot your password?
                </a>
              </div>
              {errors.credential && (
                <p className="popup-error-msg">{errors.credential}</p>
              )}
                {errors.signUp && (
                  <p className="popup-error-msg mt-0">{errors.signUp}</p>
                )}
              <button
                type="submit"
                className="btn filled primary br-round shadow h-lg w-lg mb-4"
              >
                LOGIN
              </button>
              <div className="flex align-center justify-center mt-4 mb-4">
                <p className="fs-md color-black-60 mr-2">or log in with:</p>
                <ul className="social-signup flex align-center">
                  <li>
                    {/*<GoogleSignIn/>*/}
                    <GoogleLogin
                      clientId="601602683345-ei1higg51861quhe01d9ltv55dfm1um6.apps.googleusercontent.com"
                      onSuccess={this.signInWithGoogleSuccess}
                      onFailure={this.signWithGoogleFailure}
                      render={renderProps => (
                        <button
                          onClick={renderProps.onClick}
                          className="btn-icon"
                          disabled={renderProps.disabled}
                        >
                          <img src={google} alt="Google"/>
                        </button>
                      )}
                      buttonText="Login"
                      cookiePolicy={'single_host_origin'}
                    />
                  </li>
                  <li>
                    <LinkedIn
                      clientId="86lnk527vnve2n"
                      onFailure={this.signInWithLinkedInFailure}
                      onSuccess={this.signInWithLinkedInSuccess}
                      redirectUri="https://docpool.codebnb.me/linkedin"
                      scope="r_liteprofile,r_emailaddress"
                      renderElement={({onClick, disabled}) => (
                        <button
                          className="btn-icon"
                          type={"button"}
                          onClick={onClick}
                        >
                          <img src={linkedin} alt="Linkedin"/>
                        </button>
                      )}
                    />
                  </li>
                </ul>
              </div>
              <p className="fs-md">
                Don't have an account?{" "}
                <a
                  href="/"
                  className="color-primary"
                  onClick={e => this.handleComponent(e, "signUp")}
                >
                  {" "}
                  Sign up
                </a>
              </p>
            </Form>
          </div>
        }
        {
          values.notVerifyContributer &&
          <SuccessPopup
            text={"Sorry, your account is under review for the approval"}
            onClose={this.props.onClose}
            icon="lock"
            title={false}
            modalName="modalSignIn"
          />
        }
        {component === "forgotPassword" && (
          <ForgotPassword onClose={this.props.onClose}/>
        )}
        {component === "signUp" && <SignUp onClose={this.props.onClose}/>}
      </Fragment>
      /* Login popup end */
    );
  }
}

const SignInFormik = withFormik({
  mapPropsToValues({email, password}) {
    return {
      email: email || "",
      password: password || "",
      rememberme: false
    };
  },

  validationSchema: Yup.object().shape({
    email: Yup.string()
      .trim()
      .matches(/^[^@]+@[^@]+$/, {
        message: "Please provide a valid email.",
        excludeEmptyString: true
      })
      .max(200, "Maximum number of symbols is 200.")
      .required("This field is mandatory."),
    password: Yup.string()
      .min(6, "The Password field must be 6 or more characters.")
      .max(20, "Please provide a valid password.")
      .required("This field is mandatory.")
  }),

  async handleSubmit(values, {props, setErrors, setValues}) {
    const data = {
      email: values.email.trim(),
      password: values.password
    };
  
    const res = await props.signinUser(data);
    
    if (res && res.payload){
      if (res && res.payload && res.payload.status === 200) {
        privateApi.defaults.headers.Authorization = "Token " + res.payload.data.token;
        const role = res.payload.data.role;
  
        switch (role){
          case 2: window.location.replace("/admin");
            break;
          case 3: window.location.replace("/editor");
            break;
          case 4: window.location.replace("/lawyer");
        }

        if (values.rememberme) {
          localStorage.setItem("auth", res.payload.data.token);
          localStorage.setItem("role", res.payload.data.role);
          localStorage.setItem("id", res.payload.data.id);
        } else if (!values.rememberme) {
          sessionStorage.setItem("auth", res.payload.data.token);
          sessionStorage.setItem("role", res.payload.data.role);
          localStorage.setItem("id", res.payload.data.id);
        }
        props.loggedIn();
        props.onClose("modalSignIn");
      } else if( res && res.payload && res.payload.status === 401){
        setErrors({ credential: "User with these credentials doesn't exist." });
      } else if( res && res.payload && res.payload.status === 401 && res.payload.data.email
        && res.payload.data.email.length && res.payload.data.email[0] === "Invalid email." ){
        setErrors({ credential: "User with this credentials doesn't exist" });
      }else if( res && res.payload && res.payload.status === 401 && res.payload.data.non_field_errors
        && res.payload.data.non_field_errors.length){
        if ( res.payload.data.non_field_errors[0] === "Cannot log in...." ){
          setErrors({ credential: "User with this credentials doesn't exist" });
        }else if (res.payload.data.non_field_errors[0] === "User not found."){
          setErrors({ credential: "User with this credentials is not found" });
        }else if (res.payload.data.non_field_errors[0] === "User with these credentials doesn't exist."){
          setErrors({ credential: "User with these credentials doesn't exist." });
        }
      }else if (res && res.payload && (res.payload.status === 400 || res.payload.status === 401)) {
        setErrors({ credential: "Email address and password you entered don't match. Please try again" });
      } else if (res && res.payload && res.payload.status === 403 && res.payload.data.role && res.payload.data.role === 4) {
        if (res.payload.data && res.payload.data.message === "Not active user.") {
          setErrors({credential: "Sorry, your application has not been approved by the Administrator."});
        } else {
          setValues({
            ...values,
            notVerifyContributer: true
          });
        }
      }
    }
  }
})(Login);

export default withRouter(connect(
  ({documents}) => ({document: documents.documentById.data}),
  {
    signinUser,
    signUpWithGoogle,
    signUpWithLinkedIn,
    loggedIn,
  }
)(SignInFormik));
