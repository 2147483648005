import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withFormik, Form, Field} from "formik";
import * as Yup from "yup";
import Loader from 'react-loader-spinner';
import * as fileType from "file-type";

import {
  getDocuments,
  getDocumentByIdWithPermission,
  getResourceTypes,
  getLowAreas,
  createDocuments,
} from "../../store/actions/documents";

import ReactSelect from "../admin/select";
import docs from "../../assets/img/docs.svg";
import improveUrl from '../../helpers/improve';
import Modal from 'react-responsive-modal';


class ImportPage extends Component {
  state = {
    selectedFile: null,
    loaded: 0,
    resourceOptions: [],
    lowAreasOptions: [],
    resources: "",
    lowAreas: "",
    importing: false,
  };

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.status && nextProps.status !== this.props.status){
      this.setState({ importing: true });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.allLowAreas !== this.props.allLowAreas) {
      const lowAreas = this.props.allLowAreas.allLowAreas;
      const lowAreasOptions = [];
      lowAreas &&
      lowAreas.map(data =>
        lowAreasOptions.push({value: data.id, label: data.name})
      );
      this.setState({lowAreasOptions});
    }
    if (prevProps.allResources !== this.props.allResources) {
      const resources = this.props.allResources.allResources;
      const resourceOptions = [];
      resources &&
      resources.map(data =>
        resourceOptions.push({value: data.id, label: data.name})
      );
      this.setState({resourceOptions});
    }
  }

  async componentDidMount() {
    await this.props.getResourceTypes();
    await this.props.getLowAreas();
    const lowAreas = this.props.allLowAreas.allLowAreas;
    const resources = this.props.allResources.allResources;
    const lowAreasOptions = [];
    const resourceOptions = [];
    lowAreas &&
    lowAreas.map(data =>
      lowAreasOptions.push({value: data.id, label: data.name})
    );
    resources &&
    resources.map(data =>
      resourceOptions.push({value: data.id, label: data.name})
    );
    this.setState({lowAreasOptions, resourceOptions});
    this.props.setValues({
      ...this.props.values
    });
  }

  checkextension = file => {

    const fileExt = file.name.split('.').pop().toLowerCase();
    var that = this;

    const blob = file.slice(0, fileType.minimumBytes);
    const reader = new FileReader();
    reader.onloadend = function(e) {
      if (e.target.readyState !== FileReader.DONE) {
        return;
      }
      const bytes = new Uint8Array(e.target.result);

      (async () => {
        const result = await fileType.fromBuffer(bytes);
        if(fileExt === 'docx' && result.ext === 'cfb'){
            that.props.setValues({
              ...that.props.values,
              fileError: "*The file format has been changed and is invalid"
            });
        }
      })();
    };
    reader.readAsArrayBuffer(blob);

    if (/\.(doc|docx)$/i.test(file.name) === false) {
      this.props.setValues({
        ...this.props.values,
        fileError: "*The file format must be '.doc' or '.docx'"
      });
    } else {
      this.props.setValues({...this.props.values, fileError: ""});
    }
  };

  handleSubmit() {
    this.setState({ importing: false });
    this.props.handleSubmit();
  }

  onCloseFailurePopup = async () => {
    await this.props.setStatus({});
    this.setState({ importing: false });
  };

  render() {
    const { resourceOptions, lowAreasOptions, importing } = this.state;
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      getResourceTypes,
      getLowAreas,
      status,
    } = this.props;

    const lowNext = this.props.allLowAreas.next && this.props.allLowAreas.next.split("=")[1];
    const resourceNext = this.props.allResources.next && this.props.allResources.next.split("=")[1];
    const fileName = values.file.name;
    const isNewDocument = this.props.location.pathname.includes('new-document');
    const isFailed = status && status.documentIsNotConvertable;

    return (
      <div>
        <main className="admin-content import">
          <Form>
                  {
                    importing? (
                      <div className='import-loader'>
                        <Loader
                          type="ThreeDots"
                          color="#5386D4"
                          height={20}
                          width={60}
                        />
                      </div>
                    ):(
                  <div className="container-sm">
                    <div className="flex align-center justify-between mb-5">
                      <h2 className="font-secondary fw-600">{`${isNewDocument ? 'CREATE' : 'IMPORT'}`} NEW DOCUMENT</h2>
                      <button
                          className="btn filled primary h-lg br-round shadow w-lg"
                          onClick={() => this.handleSubmit() }
                          type="button"
                      >
                        {`${isNewDocument ? 'Create' : 'Import'}`}
                      </button>
                    </div>
                    {/* Import or create document start */}
                    {
                      !isNewDocument && (
                          <div className="card br-round pt-6 pr-5 pb-6 pl-5 mb-3 general-info">
                            <h5 className="color-primary fw-700 font-secondary text-uppercase text-xs-center mb-4">
                              IMPORT DOCUMENT
                            </h5>

                            {/* When file will be chosen to be uploaded another view needs to be displayed based on the requirement.
                                 For this please add "uploaded" class to the file-import div */}
                            <div className={`file-import ${values.file ? "uploaded" : ""} ${touched.file && errors.file ? "with-error" : ""}`}>
                              <div className="upload-section">
                                <div
                                    className={`file-upload h-md ${
                                        touched.file && errors.file ? "invalid" : ""
                                    }`}
                                >
                                  <label
                                      htmlFor="file-upload"
                                      className="file-upload-label"
                                  >
                                    <input
                                        id="file-upload"
                                        name="file"
                                        type="file"
                                        className="file-upload-input"
                                        accept=".docx,.doc,"
                                        onChange={event => {
                                          this.checkextension(event.currentTarget.files[0]);
                                          setFieldValue("file", event.currentTarget.files[0]);
                                          event.currentTarget.value = "";
                                        }}
                                    />
                                    <i className="icon-upload fs-xxl">{""}</i>
                                    <p className="fs-sm mt-1">Upload File</p>
                                  </label>
                                </div>
                              </div>

                              <div className="upload-process flex align-center justify-between">
                                <div className="doc-image">
                                  <img src={docs} alt="Doc file"/>
                                </div>
                                <div className="doc-info">
                                  <p className="font-secondary fw-600 color-grey mb-2 ellipsis">
                                    Choice of {fileName}
                                  </p>
                                  {/*For showing the progress of the uploaded file please add necessary percents to the dic with "filled" class inside progress-bar div  */}
                                  <div className="progress-bar mr-4">
                                    <div
                                        className="filled"
                                        style={{width: `${values.loaded}%`}}
                                    >
                                      {""}
                                    </div>
                                  </div>
                                  <p className="fs-xs color-grey mt-1">{(values.file.size / 1024).toFixed(2)} kb</p>
                                </div>

                                <button
                                    type={"button"}
                                    className="btn-icon fs-xl mt-2 ml-3 p-0"
                                    onClick={() => this.props.setValues({...this.props.values, file: ""})}
                                >
                                  <i className="icon-delete color-warning">{""}</i>
                                </button>
                              </div>
                              {/* For required state add "invalid" class to the "file-upload" div */}
                              {touched.file && errors.file && (
                                  <p className="error-msg width-md">{errors.file}</p>
                              )}
                            </div>

                            <p className="info-text">
                              Please do not upload documents containing drafting tips or footnotes.  The system will only accept documents in Word format without drafting tips and footnotes.
                            </p>
                          </div>
                      )
                    }
                    {/* Import or create document end */}

                    {/* General info start */}
                    <div className="card br-round pt-6 pr-5 pb-6 pl-5 mb-3 general-info highlight">
                      <h5 className="color-primary fw-700 font-secondary text-uppercase text-xs-center mb-4">
                        GENERAL INFORMATION
                      </h5>
                      <div
                          className={`text-field br-corner ${touched.name &&
                          errors.name &&
                          "invalid"}`}
                      >
                        <Field
                            type="text"
                            placeholder="Document title*"
                            name="name"
                            value={values.name}
                        />

                        {touched.name && errors.name && (
                            <p className="error-msg">{errors.name}</p>
                        )}
                      </div>

                      <div className="flex justify-between align-center">
                        {/* add "disabled" className if you want to disabled select */}
                        <ReactSelect
                            hideLabel={true}
                            component="left"
                            value={values.resources}
                            errors={errors.resources}
                            touched={touched.resources}
                            options={resourceOptions}
                            type={"resources"}
                            placeholder="Document type*"
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            method={getResourceTypes}
                            page={resourceNext}
                        />

                        <ReactSelect
                            hideLabel={true}
                            component="right"
                            value={values.lowAreas}
                            errors={errors.lowAreas}
                            touched={touched.lowAreas}
                            options={lowAreasOptions}
                            type={"lowAreas"}
                            placeholder="Legal area*"
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                            method={getLowAreas}
                            page={lowNext}
                        />
                      </div>
                    </div>
                    {/* General info end */}

                    <div className="card br-round pt-8 pr-6 pb-10 pl-6">
                      <div className="flex align-center justify-between">
                        <div
                            className={`text-field br-corner w-50 mr-3 ${touched.by &&
                            errors.by &&
                            "invalid"}`}
                        >
                          <Field
                              type="text"
                              placeholder="By*"
                              name="by"
                              value={values.by}
                          />
                          {touched.by && errors.by && (
                              <p className="error-msg">{errors.by}</p>
                          )}
                        </div>

                        <div
                            className={`text-field br-corner icon right w-50 ml-3 ${
                                touched.hyperlink && errors.hyperlink && "invalid"} `}
                        >
                          <Field
                              type="text"
                              placeholder="By-Hyperlink"
                              name="hyperlink"
                              value={values.hyperlink}
                          />
                          <i className="icon-link">{""}</i>
                          {touched.hyperlink && errors.hyperlink && (
                              <p className="error-msg">{errors.hyperlink}</p>
                          )}
                        </div>

                      </div>

                      <label className="checkbox round mt-4 mb-6">
                        <input
                            type="checkbox"
                            className="checkbox-control"
                            name="andCheckbox"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={
                              !!values.andCheckbox
                                  ? !!values.andCheckbox.toString()
                                  : undefined
                            }
                        />
                        <span className="check-icon">{""}</span>
                        <span className="checkbox-label">
                                        And -
                                        <span className="fw-700">
                                          Individual / Organization name
                                        </span>
                                    </span>
                      </label>
                      <div className="flex align-center justify-between">
                        <div
                            className={`text-field br-corner w-50 mr-3 ${!values.andCheckbox &&
                            "disabled"} ${touched.andBy &&
                            errors.andBy &&
                            "invalid"}`}
                        >
                          <Field
                              type="text"
                              placeholder="And*"
                              name="andBy"
                              value={values.andBy}
                          />
                          {touched.andBy && errors.andBy && (
                              <p className="error-msg">{errors.andBy}</p>
                          )}
                        </div>
                        <div
                            className={`text-field br-corner icon right w-50 ml-3 ${!values.andCheckbox &&
                            "disabled"} ${touched.andHyperlink &&
                            errors.andHyperlink &&
                            "invalid"}`}
                        >
                          <Field
                              type="text"
                              placeholder="And-Hyperlink"
                              name="andHyperlink"
                              value={values.andHyperlink}
                          />
                          <i className="icon-link">{""}</i>
                          {touched.andHyperlink && errors.andHyperlink && (
                              <p className="error-msg">{errors.andHyperlink}</p>
                          )}
                        </div>
                      </div>
                      <label
                          className={`checkbox round mt-4 mb-6 ${!values.andCheckbox &&
                          "disabled"} `}
                      >
                        <input
                            type="checkbox"
                            className="checkbox-control"
                            name="ofCheckbox"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            checked={
                              values.andCheckbox
                              && (values.ofCheckbox
                                      ? values.ofCheckbox
                                      : undefined
                              )
                            }
                        />
                        <span className="check-icon">{""}</span>
                        <span className="checkbox-label">
                    {" "}
                          Of -
                    <span className="fw-700">
                      {" "}
                      Law firm / Organization name
                    </span>
                  </span>
                      </label>
                      <div className="flex align-center justify-between">
                        <div
                            className={`text-field br-corner w-50 mr-3 ${(!values.andCheckbox || !values.ofCheckbox) &&
                            "disabled"} ${touched.ofBy && errors.ofBy && "invalid"}`}
                        >
                          <Field
                              type="text"
                              placeholder="Of*"
                              name="ofBy"
                              value={values.ofBy}
                          />
                          {touched.ofBy && errors.ofBy && (
                              <p className="error-msg">{errors.ofBy}</p>
                          )}
                        </div>
                        <div
                            className={`text-field br-corner icon right w-50 ml-3 ${(!values.andCheckbox || !values.ofCheckbox) &&
                            "disabled"} ${touched.ofHyperlink &&
                            errors.ofHyperlink &&
                            "invalid"}`}
                        >
                          <Field
                              type="text"
                              placeholder="Of-Hyperlink"
                              name="ofHyperlink"
                              value={values.ofHyperlink}
                          />
                          <i className="icon-link">{""}</i>
                          {touched.ofHyperlink && errors.ofHyperlink && (
                              <p className="error-msg">{errors.ofHyperlink}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                    )
                  }
                </Form>
          <Modal
            open={isFailed}
            onClose={this.onCloseFailurePopup}
          >
            <div className='popup-content'>
              <p className="text-xs-center color-black-70 mt-5 ml-2">This document cannot be converted.</p>
              <div className="buttons mt-10 text-xs-center">
                <button
                  onClick={this.onCloseFailurePopup}
                  type="button"
                  className="btn filled primary br-round h-lg w-md fs-md shadow ml-3"
                >
                  Ok
                </button>
              </div>
            </div>
          </Modal>
        </main>
      </div>
    );
  }
}

const ImportPageFormik = withFormik({
  mapPropsToValues({
                     lowAreas,
                     resources,
                     name,
                     by,
                     hyperlink,
                     andBy,
                     andHyperlink,
                     andCheckbox,
                     ofCheckbox,
                     ofHyperlink,
                     ofBy,
                     file,
                     loaded
                   }) {
    return {
      lowAreas: lowAreas || "",
      resources: resources || "",
      name: name || "",
      by: by || "",
      hyperlink: hyperlink || "",
      andCheckbox: andCheckbox || false,
      ofCheckbox: ofCheckbox || false,
      andBy: andBy || "",
      andHyperlink: andHyperlink || "",
      ofBy: ofBy || "",
      ofHyperlink: ofHyperlink || "",
      file: file || "",
      loaded: loaded || 0
    };
  },
  validationSchema: Yup.object().shape({
    lowAreas: Yup.string().required("This field is mandatory."),
    resources: Yup.string().required("This field is mandatory."),
    hyperlink: Yup.string()
      .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        "The provided link should be valid.")
      .max(200, "Maximum number of symbols is 200."),
    by: Yup.string()
      .required("This field is mandatory.")
      .max(200, "Maximum number of symbols is 200."),
    name: Yup.string()
      .trim()
      .required("This field is mandatory.")
      .max(200, "Maximum number of symbols is 200."),
    andBy: Yup.string().when("andCheckbox", {
      is: true,
      then: Yup.string()
        .required("This field is mandatory.")
        .max(200, "Maximum number of symbols is 200."),
      otherwise: Yup.string()
    }),
    andHyperlink: Yup.string()
      .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        "The provided link should be valid.")
      .max(200, "Maximum number of symbols is 200."),
    ofBy: Yup.string().when("ofCheckbox", {
      is: true,
      then: Yup.string()
        .trim()
        .required("This field is mandatory.")
        .max(200, "Maximum number of symbols is 200."),
      otherwise: Yup.string()
    }),
    ofHyperlink: Yup.string()
      .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        "The provided link should be valid.")
      .max(200, "Maximum number of symbols is 200.")
  }),

  async handleSubmit(
    formValues,
    { props, setErrors, setValues, errors, resetForm, setStatus }
  ) {

    const isNewDocument = props.location.pathname.includes('new-document');
    if (!isNewDocument && !formValues.file) {
      return setErrors({file: 'This field is mandatory.'});
    }

    const values = formValues;
    let by = true, of = true;
    if (!values.andCheckbox) {
      by = false;
      of = false;
    }

    if (!values.ofCheckbox) {
      of = false;
    }

    const data = {
      name: values.name,
      law_area: values.lowAreas.value,
      resource_type: values.resources.value,
      document_file: values.file,
      imported_by: values.by,
      url_imported_by: values.hyperlink,
      imported_with: by ? values.andBy : "",
      url_imported_with: by ? values.andHyperlink : "",
      organization_imported_with: (by && of) ? values.ofBy : "",
      url_organization_imported_with: (by && of) ? values.ofHyperlink : "",
    };


    if (values.hyperlink) {
      data.url_imported_by = improveUrl(values.hyperlink);
    }

    if (values.andHyperlink) {
      data.url_imported_with = improveUrl(values.andHyperlink);
    }

    if (values.ofHyperlink) {
      data.url_organization_imported_with = improveUrl(values.ofHyperlink);
    }

    function getFormData(object) {
      const formData = new FormData();
      Object.keys(object).forEach(key => formData.append(key, object[key]));
      return formData;
    }

    const form_data = getFormData(data);
    const callback = progressEvent => {
      setValues({
        ...values,
        loaded: (progressEvent.loaded / progressEvent.total) * 100
      });
    }

    const handleErrors = {
      name: "This field must be unique.",
      document_file: "Imported document cannot be empty",
    };

    if (values.fileError) {
      setErrors({file: values.fileError});
    } else {
      let role = localStorage.getItem('role') || sessionStorage.getItem('role');
      if (role === '2') {
        role = 'admin'
      } else if (role === '4') {
        role = 'lawyer';
      } else if(role === '3'){
        role = 'editor';
      }


      let res = await props.createDocuments(form_data, null, callback);

      if (res && res.payload && res.payload.status === 201) {
        setStatus({ importing: true });
        resetForm();
        const {id} = res.payload.data;
        let getDocument = res;
        let count = 0;

        function getDocumentInterval (){
          const isPending = getDocument.payload && getDocument.payload.data && getDocument.payload.data.status === 'pending';
          if (count < 10 ){
            if(isPending){
              getDocumentTimeout();
            }else {
              props.history.push(`/${role}/edit-document/${id}`);
            }
          } else {
            setStatus({ documentIsNotConvertable: true });
          }

          function getDocumentTimeout (){
            setTimeout(async () => {
              getDocument = await props.getDocumentByIdWithPermission(id);
              count++;
              getDocumentInterval();
            }, 2500);
          }
        }

        getDocumentInterval();
      } else if (res.payload && res.payload.data && res.payload.status === 400 && res.payload.data.document_file && res.payload.data.document_file[0] === 'The submitted file is empty.') {
        setErrors({...errors, file: 'Imported document cannot be empty.'});
      } else {
        const errors = {};
        Object.keys(res.payload.data).map(data => {
          if (res.payload.data.name && res.payload.data.name[0].includes('/')){
            errors[data] = res.payload.data.name[0];
          } else {
            errors[data] = handleErrors[data];
          }
        });
        setErrors({...errors});
      }
    }
  }
})(ImportPage);

function mapStateToProps(state) {
  return {
    user: state.auth && state.auth.user,
    documents: state.documents.documents.allDocuments,
    allResources: state.documents.resources,
    allLowAreas: state.documents.lowAreas
  };
}

export default connect(
  mapStateToProps,
  {
    getDocuments,
    getDocumentByIdWithPermission,
    getResourceTypes,
    getLowAreas,
    createDocuments
  }
)(withRouter(ImportPageFormik));

