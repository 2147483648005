import React, { Component, Fragment } from "react";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import ReactSelect from "./../select";

import SuccessRegisterPopUP from "../../../components/Homepage/successRegisterPopup";
import { HaveAccount } from './haveAccount';

import { confirmLawyer, signUpStepper } from "./../../../store/actions/auth";
import { JURISDICTIONS, PRACTICE_AREA, ORG_TYPES, BOOLEAN } from "./../options";
import improveUrl from '../../../helpers/improve';
import IAgreeWith from "./components/iAgreeWith";

class ContributurStepTwo extends Component {
  state = {
    isLicensedInactive: true
  };

  onChangeLicensed = ({ value })=> {
    this.setState({isLicensedInactive: !value})
  };

  componentDidMount() {
    if (this.props.stepperData.first_name) {
      this.props.setValues({
        ...this.props.values,
        ...this.props.stepperData
      });
    }
    if (this.props.stepperData.is_licensed && this.props.stepperData.is_licensed.label === 'Yes'){
      this.setState({ isLicensedInactive: false });
    }
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      aboutFiles,
    } = this.props;

    console.log(errors, touched);

    return (
      <Fragment>
        {!values.success ? (
          <Form>
            <div>
              <div
                className={`text-field br-corner ${touched.organization_name &&
                  errors.organization_name &&
                  "invalid"}`}
              >
                <Field
                  type="text"
                  placeholder="Organization name*"
                  name="organization_name"
                  value={values.organization_name}
                />
                {touched.organization_name && errors.organization_name && (
                  <p className="error-msg">{errors.organization_name}</p>
                )}
              </div>
              <div className="select-box">
                <ReactSelect
                  parent={'contributor'}
                  placeholder="Organization type*"
                  options={ORG_TYPES}
                  value={values.organization_type}
                  errors={errors.organization_type}
                  touched={touched.organization_type}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  name={"organization_type"}
                />
              </div>
              <div
                className={`text-field br-corner ${touched.organization_address &&
                  errors.organization_address &&
                  "invalid"}`}
              >
                <Field
                  type="text"
                  placeholder="Address of the organization*"
                  name="organization_address"
                  value={values.organization_address}
                />
                {touched.organization_address &&
                  errors.organization_address && (
                    <p className="error-msg">{errors.organization_address}</p>
                  )}
              </div>
              <div
                className={`text-field br-corner ${touched.organization_city &&
                  errors.organization_city &&
                  "invalid"}`}
              >
                <Field
                  type="text"
                  placeholder="City of the organization"
                  name="organization_city"
                  value={values.organization_city}
                />
                {touched.organization_city && errors.organization_city && (
                  <p className="error-msg">{errors.organization_city}</p>
                )}
              </div>
              <div
                className={`text-field br-corner ${touched.organization_url &&
                  errors.organization_url &&
                  "invalid"}`}
              >
                <Field
                  type="text"
                  placeholder="Organization's website"
                  name="organization_url"
                  value={values.organization_url}
                />
                {touched.organization_url && errors.organization_url && (
                  <p className="error-msg">{errors.organization_url}</p>
                )}
              </div>

              <div className="select-box">
                {/*/!*Placeholder of the select should be " Are you licensed to practice law?* " *!/*/}
                <ReactSelect
                  parent={'contributor'}
                  placeholder=" Are you licensed to practice law?*"
                  options={BOOLEAN}
                  name="is_licensed"
                  value={values.is_licensed}
                  errors={errors.is_licensed}
                  touched={touched.is_licensed}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  onChangeLicensed={this.onChangeLicensed}
                />
              </div>
              <div className="select-box">
                {/*/!*Placeholder of the select should be " In which jurisdictions are you licensed? " *!/*/}
                <ReactSelect
                  parent={'contributor'}
                  placeholder="In which jurisdictions are you licensed?"
                  options={JURISDICTIONS}
                  errors={errors.jurisdictions}
                  value={values.jurisdictions}
                  values={values}
                  touched={touched.jurisdictions}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  name={"jurisdictions"}
                  isDisabled={this.state.isLicensedInactive}
                />
              </div>
              <div
                className={`text-field br-corner ${touched.other_country &&
                  errors.other_country &&
                  "invalid"}`}
              >
                <Field
                  type="text"
                  placeholder="Other country"
                  name="other_country"
                  value={values.other_country}
                />
                {touched.other_country && errors.other_country && (
                  <p className="error-msg">{errors.other_country}</p>
                )}
              </div>
              <div className="select-box">
                {/*/!*Placeholder of the select should be " Which are your primary areas of practice?* " *!/*/}
                <ReactSelect
                  parent={'contributor'}
                  placeholder="Which are your primary areas of practice?*"
                  options={PRACTICE_AREA}
                  errors={errors.practice_areas}
                  touched={touched.practice_areas}
                  value={values.practice_areas}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  name={"practice_areas"}
                />
              </div>
              {/* Contributing lower second step content end */}
            </div>
            {/* Contributing lower content end */}
            <IAgreeWith
                values={values}
                handleChange={handleChange}
                handleBlure={handleBlur}
                aboutFiles={aboutFiles}
            />

            <div>
              {errors.searchable && touched.searchable && (
                <p className="popup-error-msg mt-0">{errors.searchable}</p>
              )}
            </div>
            <div className="mb-4">
              {/* Button needs to be added in case of second step of contributing lower */}
              <button
                className="btn outline text-primary border-primary br-round shadow h-lg w-md mr-6"
                type="button"
                onClick={e => {
                  e.preventDefault();
                  this.props.signUpStepper({
                    ...values,
                    ...this.props.firstStepData
                  });
                  this.props.handleSecondStep();
                }}
              >
                BACK
              </button>
              <button
                className="btn filled primary br-round shadow h-lg w-lg"
                type="submit"
              >
                SIGN UP
              </button>
              {/* </div> */}
            </div>
            <HaveAccount
                handleComponent={this.props.handleComponent}
            />
          </Form>
        ) : (
          <SuccessRegisterPopUP
            text={`An email was sent to your email address. Please check your email to finalize your registration`}
            onClose={this.props.onClose}
            icon="envelop"
            modalName={"modalSignUp"}
          />
        )}
      </Fragment>
    );

    /* In case of contributing lower first step the button should be NEXT*/
  }
}
const SignUpFormik = withFormik({
  mapPropsToValues({
    jurisdictions,
    practice_areas,
    organization_type,
    is_licensed,
    organization_name,
    organization_address,
    organization_city,
    organization_url,
    other_country,
    searchable,
  }) {
    return {
      jurisdictions: jurisdictions || [],
      practice_areas: practice_areas || "",
      is_licensed: is_licensed || "",
      organization_type: organization_type || "",
      organization_name: organization_name || "",
      organization_address: organization_address || "",
      organization_city: organization_city || "",
      organization_url: organization_url || "",
      other_country: other_country || "",
      searchable: searchable || false,
    };
  },
  validationSchema: Yup.object().shape({
    organization_name: Yup.string()
    .required("This field is mandatory.")
    .max(200, "Maximum number of symbols is 200."),
    organization_type: Yup.string().required("This field is mandatory."),
    organization_address: Yup.string()
    .required("This field is mandatory.")
    .max( 200, "Maximum number of symbols is 200."),
    organization_city: Yup.string().max(
      200,
      "Maximum number of symbols is 200."
    ),
    organization_url: Yup.string()
    .max(200, "cant be more than 200 characters"),
    is_licensed: Yup.string().required("This field is mandatory."),
    jurisdictions: Yup.string(),
    other_country: Yup.string().max(200, "Maximum number of symbols is 200."),
    practice_areas: Yup.string().required("This field is mandatory."),
    searchable: Yup.mixed().oneOf([true], 'You must be agree with our Terms of Use and Pricing Policy.'),
  }),

  async handleSubmit(values, { props, setErrors, setValues }) {

    const handleErrors = {
      email: "The provided email already exists.",
      role: "You should choose one of the options",
      url: "The provided link should be valid.",
    };

    const data = {
      ...props.firstStepData,
      jurisdictions: values.jurisdictions.map(data => data.value),
      is_licensed: values.is_licensed.value,
      practice_areas: [values.practice_areas.value],
      organization_type: values.organization_type.value,
      organization_name: values.organization_name,
      organization_address: values.organization_address,
      organization_city: values.organization_city,
      organization_url: values.organization_url,
      other_country: values.other_country,
      role: 4,
    };

    if (values.organization_url){
      data.organization_url = improveUrl(values.organization_url);
    }

    if (data.is_licensed && !data.jurisdictions.length){
      return setErrors({jurisdictions: 'This field is mandatory.'});
    }

    if (!data.phone_number){
      delete data.phone_number;
    }else {
      data.phone_number = data.phone_number && data.phone_number.replace("+", "");
    }
    
    if(data.is_licensed === false){
      data.jurisdictions = [];
    }

    const res = await props.confirmLawyer(data, props.lawyerToken);
    if (res && res.payload.status === 200) {
      props.onOpenModal('modalRegister', SuccessRegisterPopUP, this.onCloseModal)
    }
    if (res && res.payload.status === 400) {
      const errors = {};
      Object.keys(res.payload.data).map(
        data => (errors[data] = handleErrors[data])
      );
      setErrors({ ...errors });
    }
    res && res.payload.status === 200 && props.handleClick('isLawyerConfirm');
    if (res && res.payload.status === 400) {
      const errors = {};
      Object.keys(res.payload.data).map(
        data => (errors[data] = handleErrors[data])
      );
      setErrors({ ...errors });
    }
  }
})(ContributurStepTwo);

function mapStateToProps(state) {
  return {
    stepperData: state.auth.stepperData,
    aboutFiles: state.admin.about,
  };
}

export default connect(
  mapStateToProps,
  {
    confirmLawyer,
    signUpStepper,
  }
)(SignUpFormik);
