import React, { Component, createRef } from 'react';
import {connect} from 'react-redux';
import Modal from 'react-responsive-modal';
import {withRouter} from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { Link } from 'react-router-dom';

import {
  documentDownload,
  getDocumentById,
  getDocumentByIdWithPermission,
  getDocumentHTML,
} from '../../store/actions/documents';
import {getSubscription} from "../../store/actions/payment";

import { makeDocumentPrintable } from './documentEditor/helper';

import DownloadModal from './documentEditor/download';
import SignIn from "../Homepage/login/login";
import Loader from 'react-loader-spinner'

class Document extends Component {

  printableDocument = createRef();

  state = {
    documentTitle: null,
    imported_by: null,
    imported_by_name: null,
    creator_picture: null,
    creator_role: null,
    url_imported_by: null,
    organization_imported_with: null,
    url_organization_imported_with: null,
    openDownload: false,
    selectedFields: [],
    open: false,
    role: null,
    is_bought: null,
    loading: true,
  };

  async componentDidMount() {
    const role = localStorage.getItem('role') || sessionStorage.getItem('role');
    const token = localStorage.getItem('auth') || sessionStorage.getItem('auth');
    let id = null;
    const styleTag = document.createElement('style');
    if (role === '1' || !role) {
      id = this.props.id;
    } else {
      id = this.props.match.params.id;
    }
    let res;


    if (role ) {
      if(role === '2') {
        return this.props.history.push('/admin');
      }
      if ( role !== '4'){
        await this.props.getSubscription();
      }
      res = await this.props.getDocumentByIdWithPermission(id);
    } else {
      res = await this.props.getDocumentById(id);
    }

    const response = await this.props.getDocumentHTML(res.payload.data.document_file);
    let draftingNotes = res && res.payload && res.payload.data.draftingnote;
    const documentInner = document.querySelector('#card-body');
    const is_annual = this.props.subscription.subscription_type === 1;

    styleTag.type = 'text/css';
    styleTag.innerHTML = '@page { margin: 90px 0, .edit-doc { display:none; } } @media print{ .edit-doc { display:none; }}';

    documentInner.innerHTML = response && response.payload.data;
    await this.setState({ loading: false });



    const docContainer = document.querySelector('.card-body');
    docContainer.innerHTML = documentInner.innerHTML;


    const annotatedParagraphs = Array.from(document.querySelectorAll(`.card-body [id]`));
    this.annotationInsertHandler(annotatedParagraphs, draftingNotes, res.payload.data.is_bought, is_annual);

    const { printableDocument: { current } } = this;
    current.innerHTML = docContainer.innerHTML;

    makeDocumentPrintable( docContainer, this.printableDocument.current, styleTag, 'view');
    current.querySelectorAll('.newDraftingNote').forEach(note => {
      note.style = 'border: 1px dashed #cccccc; padding: 20px 10px; margin: 16px 0 12px';
      note.className = '';
    });

    const paragraphs = document.querySelectorAll(`.edit-doc-section`);
    paragraphs.forEach( paragraph => {
      if (paragraph.style.lineHeight === '100%'){
        paragraph.style.lineHeight = '120%';
      }
    });

    const anchorElements = document.querySelectorAll(`.card-body a`);
    anchorElements.forEach(a => {
      a.target = '_blank'
    });

    let imported_by_name;

    if (res && res.payload && res.payload.data) {
      const {
        creator_picture,
        last_modified,
        creator_role,
        name,
        is_bought,
        imported_by,
        url_imported_by,
        imported_with,
        url_imported_with,
        organization_imported_with,
        url_organization_imported_with,
      } = res.payload.data;

      const {first_name, last_name} = res.payload.data.last_editor;
      const last_editor = first_name + ' ' + last_name;
      let creator, nameForBackground;
      {
        const {first_name, last_name} = res.payload.data.user;
        creator = first_name + ' ' + last_name;
        nameForBackground = first_name[0].toUpperCase() + ' ' + last_name[0].toUpperCase();
      }
 
      if (imported_by.length) {
        const name = imported_by.split(' ');
        const firstName = name[0] ? name[0][0].toUpperCase() : '';
        const lastName = name[1] ? name[1][0].toUpperCase() : '';
        imported_by_name = firstName + lastName
      }

      this.setState({
        creator_picture,
        organization_imported_with,
        url_organization_imported_with,
        imported_by_name,
        imported_by,
        url_imported_by,
        imported_with,
        url_imported_with,
        role,
        id,
        token,
        last_modified,
        creator_role,
        last_editor,
        creator,
        nameForBackground,
        documentTitle: name,
        is_bought,
        is_annual,
      })
    }
  }

  annotationInsertHandler = (annotatedParagraphs, annotations, is_bought, isAnnual) => {
    annotatedParagraphs.forEach(paragraph => {
      annotations.forEach(note => {
        if (+paragraph.id === note.paragraph_id) {
          const annotationContainer = document.createElement('div');
          const annotationContent = document.createElement('p');
          const annotationTitle = document.createElement('p');
          const showButton = document.createElement('button');
          showButton.className = 'btn-manage btn-expand';
          annotationTitle.innerHTML = note.title;
          annotationTitle.className = 'note-title';
          annotationTitle.append(showButton);
          annotationContent.innerHTML = note.content;
          annotationContent.className = 'note-desc';
          annotationContainer.className = 'draftingNote';
          annotationContainer.appendChild(annotationTitle);
          annotationContainer.appendChild(annotationContent);
          insertAfter(annotationContainer, paragraph);
          showButton.onclick = e => {
            if (annotationContainer.className.includes('expanded')) {
              annotationContainer.className = 'draftingNote';
            } else {
              if (!this.state.role || this.state.role === "1" && !(is_bought || isAnnual)){
                return
              }
              annotationContainer.className += ' expanded';
            }
          }
        }
      });
    });

    function insertAfter(newNode, referenceNode) {
      referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
    }
  };

  onCloseErrorModal = () => {
    this.setState({open: false});
    this.props.history.push('/');
  };
  onCloseDownload = () => {
    this.setState({openDownload: false, selectedFields: []})
  };

  formFieldChangeHandler = e => {
    const {selectedFields} = this.state;
    if (selectedFields.length < 2) {
      const name = e.target.attributes.type.ownerElement.name;
      if (name !== selectedFields[0]) {
        selectedFields.push(name)
      }
    }
    this.setState({selectedFields})
  };

  downloadHandler = values => {
    const id = this.state.id;
    const defaultValue = {
      chooseOption: "false",
      chooseFormat: "word",
    };
    values = {...defaultValue, ...values};
    this.props.documentDownload(id, values);
  };

  onBeforePrint = async () => {
    await new Promise((resolve, reject) => {
        const { is_bought, is_annual } = this.state;
        (is_bought || is_annual)? resolve(): reject();
      })
  }

  printHandler = () => {
    const { token, is_bought, id, is_annual, role } = this.state;

    if (role === '4') return;

    if (!token){
      this.props.onOpenModal("modalSignIn", SignIn);
    } else if(!is_bought && !is_annual){
      this.props.history.push('/pricing', { document_id: id });
    }
  }

  handleDownload = () => {
    const { is_bought, id, role } = this.state;
    const token = sessionStorage.auth || localStorage.auth;

    if (role === '4') return;

    if (token) {
      if (is_bought){
        return this.setState({openDownload: !this.state.openDownload});
      }
      this.props.history.push('/pricing', { document_id: id });
    } else {
      this.props.onOpenModal("modalSignIn", SignIn);
    }
  };
  
  renderBySection = () => {
    const {
      imported_by,
      url_imported_by,
      imported_with,
      url_imported_with,
      organization_imported_with,
      url_organization_imported_with,
    } = this.state;
    
    return (
      <p className="italic">
        {
          url_imported_by ? (
            <>
              <a
              style={{ color: '#5386D4' }}
              href={`${url_imported_by}`}
              target='_blank'
            >{imported_by}</a>
            </>
          ) : (
            <>
              By {imported_by}
            </>
          )
        }
        {
          ' '
        }
        {
          url_imported_with ? (
            <>
              and <a
              style={{ color: '#5386D4' }}
              href={`${url_imported_with}`}
              target='_blank'
            > {imported_with}</a>
            </>
          ) : (
            <>
              {
                imported_with && (<>
                  and {imported_with}
                </>)
              }
            </>
          )
        }
        {
          '   '
        }
        {
          url_organization_imported_with ? (
            <>
              of <a
              style={{ color: '#5386D4' }}
              href={`${url_organization_imported_with}`}
              target='_blank'
            >{organization_imported_with}</a>
            </>
          ) : (
            <>
              {
                organization_imported_with &&
                <>
                  of {organization_imported_with}
                </>
              }
            </>
          )
        }
      </p>
    );
  };

  render() {
    const {
      creator_picture,
      imported_by,
      url_imported_by,
      imported_with,
      url_imported_with,
      organization_imported_with,
      url_organization_imported_with,
      openDownload,
      selectedFields,
      open,
      role,
      last_modified,
      creator_role,
      documentTitle,
      last_editor,
      creator,
      nameForBackground,
      id,
      is_bought,
      is_annual,
      loading,
    } = this.state;


    const disabled = selectedFields.length !== 2;
    const editTime = new Date(last_modified).toLocaleDateString();

    return (
      <main className={`${role && role !== '1' ? 'admin-content manage' : 'w-100'}`}>
        <div className="files">
          <div id="card-body" style={{ display: 'none' }}> </div>
          {
            loading? (
                <div className={'loader-container'}>
                  <Loader
                      type="ThreeDots"
                      color="#5386D4"
                      height={20}
                      width={60}/>
                </div>
            ):(
                <div className="edit-doc container ">
                  {
                    !loading && (!role || role === '1' && !(is_bought || is_annual))  &&
                    <p className="edit-doc-info">
                      To download and view this document (including its drafting tips), please purchase a plan
                      <Link to={{
                        pathname: '/pricing',
                        state: { document_id: id, is_bought },
                      }} className="color-primary fw-600"> here</Link>.
                    </p>
                  }

                  <div className="flex justify-between edit-doc-items ">
                    <div className="edit-doc-content ">
                      {/*{*/}
                      {/*  (creator_role === 2 && false) ? (*/}
                      {/*      !loading && (*/}
                      {/*          <div className="flex align-center justify-between mb-2 page-header doc-view-mode-header">*/}
                      {/*            <h2 className="font-secondary fw-600">{documentTitle}</h2>*/}
                      {/*            <div className="flex card br-corner pt-4 pb-4 pr-6 pl-6 ml-6">*/}
                      
                      {/*              <ul className="list-edit ">*/}
                      {/*                <li>*/}
                      {/*                  <label className="label">By:</label>*/}
                      {/*                  <span className="value">John Dorsey</span>*/}
                      {/*                </li>*/}
                      {/*                <li>*/}
                      {/*                  <label className="label">Last edit:</label>*/}
                      {/*                  <span className="value">{editTime}</span>*/}
                      {/*                </li>*/}
                      {/*              </ul>*/}
                      {/*            </div>*/}
                      {/*          </div>*/}
                      {/*      )*/}
                      {/*  ) : (*/}
                            <div className="flex align-center justify-between mb-2 page-header doc-view-mode-header">
                              <h2 className="font-secondary fw-600">{documentTitle}</h2>
                              <div className="flex card br-corner pt-4 pb-4 pr-6 pl-6 ml-6">
                                <ul className="list-edit italic">
                                  <li>
                                    <label className="label">By: </label>
                                    <span> {this.renderBySection()}</span>
                                  </li>
                                  <li>
                                    <label className="label">Last edit:</label>
                                    <span className="value">{editTime}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                      {/*  )*/}
                      {/*}*/}

                      <div className="card doc-content br-corner view-mode">
                        <div className="text-xs-right card-header pt-2 pb-2">
                          {
                            is_annual || is_bought? (
                                <ReactToPrint
                                    content={ () => this.printableDocument.current }
                                    onBeforePrint={this.onBeforePrint}
                                    trigger={() =>
                                        <a>
                                          <button className="btn link primary w-sm h-sm fw-600">
                                            <i className="icon-print mr-1">{''}</i> Print
                                          </button>
                                        </a>
                                    }
                                />
                            ) : (
                                <button
                                    className="btn link primary w-sm h-sm fw-600"
                                    onClick={this.printHandler}
                                >
                                  <i className="icon-print mr-1">{''}</i> Print
                                </button>
                            )
                          }
                          <button className="btn link primary w-sm h-sm fw-600" onClick={this.handleDownload} >
                            <i className="icon-download mr-1">{''}</i>Download
                          </button>
                        </div>
                        <DownloadModal
                            onClose={this.onCloseDownload}
                            open={openDownload}
                            downloadHandler={this.downloadHandler}
                            formFieldChangeHandler={this.formFieldChangeHandler}
                            disabled={disabled}
                        />
                        <Modal
                            open={open}
                            onClose={this.onCloseErrorModal}
                        >
                          <div className="popup-content">
                            Something went wrong :(
                            please try again
                          </div>
                        </Modal>

                        <div className="card-body "> </div>

                        <div style={{ position: 'absolute', zIndex: 0, width: 0, height: 0, overflow: 'hidden'}}>
                          <div
                              id='printableDocument'
                              ref={this.printableDocument}
                              style={{ padding: '50px'}}
                          > </div>
                        </div>

                      </div>
                      {/* Edit doc content end */}
                    </div>
                    {/*DocumentFormStart*/}
                  </div>
                </div>
            )
          }
        </div>
      </main>
    );
  }
}

const mapDispatchToProps = {
  getDocumentHTML,
  documentDownload,
  getDocumentById,
  getDocumentByIdWithPermission,
  getSubscription,
};

const mapStateToProps = ({payment, auth}) => ({
  subscription: payment.subscription || {},
  auth,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Document))
