import React, { Component } from 'react';
import AddPricingPlan from "./addPricingPlan";
import Modal from "react-responsive-modal";

class Pricing extends Component {

  state = {
    openAddPlan: false
  };

  onOpenAddPlanModal = () => {
    this.setState({ openAddPlan: true });
  };

  onCloseAddPlanModal = () => {
    this.setState({ openAddPlan: false });
  };

  render () {
    const { openAddPlan } = this.state;

    return (
      <div>
        <main className="admin-content admin-pricing manage">
          <div className="container">

            <div className="flex align-center justify-between mb-8">
              <h2 className="font-secondary text-uppercase fw-600">Pricing Plan</h2>
              <button
                className="btn filled primary h-lg br-round shadow w-md flex justify-center align-center"
                onClick={this.onOpenAddPlanModal}
                disabled={true}
              >
                <i className="icon-plus fs-xl mr-1"> </i> ADD NEW
              </button>

              {/*  Add new pricing plan popup start */}
              <Modal open={openAddPlan} center onClose={this.onCloseAddPlanModal}>
                <AddPricingPlan />
              </Modal>
              {/* Add new pricing plan popup end */}

            </div>

            <ul className="pricing-plans">

              <li>
                <div className="plan-head">
                  <p className="plan-title">SINGLE DOCUMENT: ONE-TIME PAYMENT</p>
                  <p className="plan-price">$49</p>
                </div>
                <div className="plan-body">
                  <ul className="plan-features">
                    <li>Access to a single document for 10 days</li>
                    <li>View drafting tips for the document</li>
                    <li>Download/print the document in Word or PDF</li>
                    <li>One-time fee</li>
                  </ul>
                  <div className="btn-group">
                    <button
                      disabled={true}
                      type="button"
                      className="btn filled primary h-lg br-round shadow">
                      <i className="icon-edit fs-lg mr-2"> </i>
                      EDIT
                    </button>
                    <button disabled={true} type="button" className="btn outline border-black-20 text-black-50 br-round h-lg">
                      <i className="icon-delete color-warning  fs-xl mr-1"> </i>
                      DELETE
                    </button>
                  </div>
                </div>
              </li>

              <li>
                <div className="plan-head">
                  <p className="plan-title">Annual subscription (Auto-Renewal)</p>
                  <p className="plan-price">$99</p>
                </div>
                <div className="plan-body">
                  <ul className="plan-features">
                    <li>Access to all documents for one year</li>
                    <li>View drafting tips for all documents</li>
                    <li>Download/print all documents in Word or PDF</li>
                    <li>Auto-renewal</li>
                  </ul>
                  <div className="btn-group">
                    <button disabled={true} type="button" className="btn filled primary h-lg br-round shadow">
                      <i className="icon-edit fs-lg mr-2"> </i>
                      EDIT
                    </button>
                    <button disabled={true} type="button" className="btn outline border-black-20 text-black-50 br-round h-lg">
                      <i className="icon-delete color-warning  fs-xl mr-1"> </i>
                      DELETE
                    </button>
                  </div>
                </div>
              </li>

            </ul>

          </div>
        </main>
      </div>
    )
  }
}

export default Pricing
