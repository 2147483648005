import React, { Component } from 'react'
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

import {clearDocuments} from '../../store/actions/documents';

class SearchComponent extends Component {
  state = {
    value: ''
  }

  handleChange = e => {
    this.setState({ value: e.target.value })
    this.props.onHandleChange({ searchValue: e.target.value })
  }

  handleClick = (e) => {
    e.preventDefault();
    this.props.clearDocuments();
    this.props.searchResults(this.props.searchValue);
    this.props.history.push("/")
  }

  render () {
    return (
     <div
      className={`text-field br-corner icon right-bg ${
       this.props.headerClass
      }`}
     >
       <form onSubmit={(e) => this.handleClick(e)} >
         <input
          type="text"
          placeholder="Search"
          value={this.props.searchValue}
          onChange={this.handleChange}
         />
         <span className="icon pointer" onClick={this.handleClick}>
          <i className="icon-search">{''}</i>
        </span>
       </form>

     </div>
    )
  }
}


export default withRouter(connect(null, {clearDocuments})(SearchComponent));