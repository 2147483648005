import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { DeleteModal } from '../../common/modal';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import DownloadModal from './download';
import DocumentEditor from './editor';
import Select from '../../admin/select';
import SaveModal from '../modal';

import {
  getLowAreas,
  getLowAreasById,
  getResourcesById,
  getResourceTypes,
  documentDownload,
  documentUpdate,
  documentPublish,
  documentDelete,
  getDocumentHTML,
  getDocumentByIdWithPermission,
} from '../../../store/actions/documents'

import ReactToPrint from 'react-to-print'
import improveUrl, { roleChecker } from '../../../helpers/improve'
import { buttonPositionChangeHandler, setMouseOut, setMouseMove, makeDocumentPrintable } from './helper.js'
import {DOCUMENT_DELETE_CONFIRM_MESSAGE, DOCUMENT_DELETE_MESSAGE} from '../constants';
import document from "../document";

class EditDocument extends Component {

  printableDocument = React.createRef();
  state = {
    openDownload: false,
    saveModalOpen: false,
    publishModalOpen: false,
    errorModalOpen: false,
    save: false,
    payload: null,
    lowAreaOptions: [],
    resourceOptions: [],
    formatData: {},
    selectedFields: [],
    draftingNotes: [],
    checked: null,
    andCheckbox: null,
    ofCheckbox: null,
    documentReference: null,
    isNewDoc: false,
    documentHtml: null,
    printableDocument: null,
    draftingNote: null,
    error: null,
    loading: true,
    is_cleaned: false,
    errorMessage: null,
    openDeleteModal: false,
    openDeleteConfirmModal: false,
    has_subscribers: null,
  };

  async componentDidMount () {
    await this.props.getLowAreas();
    await this.props.getResourceTypes();
    const { lowAreas, resources, match: { params: { id } } } = this.props;
    const auth = localStorage.auth ? localStorage.auth : sessionStorage.auth;
    const role = roleChecker();
    const res = await this.props.getDocumentByIdWithPermission(id, auth);
    let documentData;
    if (res.payload && res.payload.data) {
      documentData = res.payload.data;
    } else {
      this.props.history.push('/')
    }
    const { last_editor, imported_by } = documentData;
    const lastEditor = last_editor.first_name + ' ' + last_editor.last_name;
    this.props.getResourcesById(documentData.resource_type);
    this.props.getLowAreasById(documentData.law_area);
    let response;
    try{
      response = await this.props.getDocumentHTML(res.payload.data.document_file);
    } catch (e) {
      window.reload();
    }

    const lowAreaOptions = [];
    const resourceOptions = [];

    lowAreas.allLowAreas.map(item => {
      lowAreaOptions.push({ value: item.id, label: item.name });
    });

    resources.allResources.map(item => {
      resourceOptions.push({ value: item.id, label: item.name });
    });

    this.props.setValues({ ...this.props.values });

    this.setState({
      lowAreaOptions,
      resourceOptions,
      isNewDoc: false,
      docName: documentData && documentData.name,
      documentHtml: response.payload.data,
      printableDocument: response.payload.data,
      draftingNote: res.payload.data.draftingnote,
      has_subscribers: res.payload.data.has_subscribers,
      last_editor: lastEditor,
      imported_by,
      loading: false,
      is_cleaned: res.payload.data.is_cleaned,
      role,
    });
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const lowAreaOptions = [];
    const resourceOptions = [];

    if (prevProps.resources.allResources !== this.props.resources.allResources) {
      this.props.resources.allResources.map(item => {
        resourceOptions.push({ value: item.id, label: item.name })
      })
      this.setState({
        resourceOptions,
      })
    }

    if (prevProps.lowAreas.allLowAreas !== this.props.lowAreas.allLowAreas) {
      this.props.lowAreas.allLowAreas.map(item => {
        lowAreaOptions.push({ value: item.id, label: item.name })
      })
      this.setState({
        lowAreaOptions,
      })
    }
  }

  handleClick = () => {
    this.setState({ openDownload: !this.state.openDownload })
  };

  onCloseModal = async name => {
    this.setState({ [name]: !this.state[name] });
  };

  formFieldChangeHandler = async (e, value) => {
    const { selectedFields } = this.state;
    if (selectedFields.length < 2) {
      const name = e.target.attributes.type.ownerElement.name;
      if (name !== selectedFields[0]) {
        selectedFields.push(name);
      }
    }
    this.setState({ selectedFields });
  };

  downloadHandler = values => {
    const id = this.props.documentData.id;
    const defaultValue = {
      chooseOption: 'false',
      chooseFormat: 'word',
    };
    values = { ...defaultValue, ...values };
    this.props.documentDownload(id, values, this.state.docName);
  };

  addContentOfDocument = printableDocument => {
    this.setState({ printableDocument })
  }

  onsubmitHandler = (values, eventName) => {
    this.setState({ loading: true });
    const queryMaker = new Promise(resolve => {
    const editor = window.document.getElementById('editor');
    const notes = Array.from(editor.getElementsByClassName('draftingNote'));
    const { andCheckbox, ofCheckbox } = values;
    const { is_cleaned } = this.state;
    const newValues = { ...values };
    const draftingNotes = [];
    const modalName = `${eventName}ModalOpen`;
    let decrementCount = 0;
    let data = {};

    if (!is_cleaned) {
      if(!editor) return;
      editor.querySelectorAll('a').forEach(anchor => {
        if (!anchor.innerText || !anchor.innerText && (!anchor.href || !anchor.href.includes('http'))) {
          anchor.remove();
        }
      });
    }

    for(let i = 0; i < notes.length; i++){
      const note = notes[i];
      const paragraph_id = note.dataset.id;
      const noteTitle = note.firstElementChild.firstElementChild.value;
      const noteContent = note.firstElementChild.lastElementChild.innerHTML;
      const noteContentText = note.firstElementChild.lastElementChild.innerText;
      const title = noteTitle.trim() ? noteTitle : '';
      const content = noteContentText.trim() ? noteContent : '';
      if (!title && !content) {
        const annotatedParagraph = document.getElementById(`${paragraph_id}`);
        const button = document.querySelector('#plus');
        annotatedParagraph.className = 'edit-doc-section';
        annotatedParagraph.removeAttribute('id');
        annotatedParagraph.onmouseenter = e => {
          buttonPositionChangeHandler(annotatedParagraph, button, paragraph_id, e);
        };
        setMouseOut(annotatedParagraph, button);
        setMouseMove(annotatedParagraph, button, id);
        decrementCount++;
        note.remove();
        continue;
      }else {
        const newId = i - decrementCount + 1;
        const annotatedParagraph = note.previousSibling;
        annotatedParagraph.setAttribute('id', `${newId}`);
        note.setAttribute('data-id', `${newId}`)
      }
      const item = { paragraph_id, title, content }
      draftingNotes.push(item);
    }

      if (newValues.url_imported_by) {
      newValues.url_imported_by = improveUrl(newValues.url_imported_by);
    }
    if (!andCheckbox) {
      newValues.imported_with = '';
      newValues.url_imported_with = '';
      newValues.organization_imported_with = '';
      newValues.url_organization_imported_with = '';
    } else if (newValues.url_imported_with) {
      newValues.url_imported_with = improveUrl(newValues.url_imported_with);
    }
    if (!ofCheckbox) {
      newValues.url_organization_imported_with = '';
      newValues.organization_imported_with = '';
    } else if (newValues.url_organization_imported_with) {
      newValues.url_organization_imported_with = improveUrl(newValues.url_organization_imported_with);
    }

    delete newValues.andCheckbox;
    delete newValues.ofCheckbox;
    const id = this.props.documentData.id;
    editor.removeAttribute('contenteditable');
    editor.id = 'forCleanUp';

    const document_html = editor.outerHTML;

    data = { ...newValues };
    data.law_area = newValues.law_area.value;
    data.resource_type = newValues.resource_type.value;
    data.draftingnote = draftingNotes;
    data.document_html = document_html;
    data.is_published = (eventName === 'publish');
    data.is_cleaned = true;
    resolve({ id, data, modalName });
    });

  queryMaker.then(async ({ id, data, modalName }) => {
    const res = await this.props.documentUpdate(id, data);

    console.log(1234, res)

    if (res.type === 'document_updated' && res.payload.status === 200) {
      const { last_modified } = res.payload.data
      const { first_name, last_name } = res.payload.data.last_editor
      const last_editor = first_name + ' ' + last_name

      this.setState({
        [modalName]: true,
        last_modified,
        last_editor,
        loading: false,
        documentHtml: res.payload.data.document_html,
        draftingNote: res.payload.data.draftingnote,
      })
    } else {

      if (res.payload.data.name.length && res.payload.data.name[0] === "This field must be unique."){
        this.props.setErrors({ ...this.props.errors, name: res.payload.data.name[0] });
      } else {
        this.setState({ errorModalOpen: true });
      }
      this.setState({ loading: false });
    }
  })

  };

  onCloseDownload = () => {
    this.setState({ openDownload: false, selectedFields: [] })
  };

  makeDocPrintable = () => {
    const { printableDocument: { current: printingDocument} } = this;
    const editor = window.document.getElementById('editor');

    printingDocument.innerHTML = editor.innerHTML;
    makeDocumentPrintable(editor, printingDocument, null, 'edit');

    printingDocument.querySelectorAll('.newDraftingNote').forEach( note => {
      note.style ="border: 1px dashed #cccccc; padding: 20px 10px; margin: 10px 0";
    });
  };

  deleteModalHandler = () => this.setState({ openDeleteModal: !this.state.openDeleteModal });

  deleteHandler = async () => {

    const { history } = this.props;

    try {
      await this.props.documentDelete(this.props.documentData.id, this.state.has_subscribers);
      await this.setState({ openDeleteModal: false, has_subscribe: false });
    } catch (e) {
      console.log(e);
    }
    finally {
      setTimeout(() => {
        history.go(-1);
      }, 800);
    }
  };

  render () {
    const {
      values,
      errors,
      touched,
      handleChange,
      setFieldValue,
      setFieldTouched,
      history,
      handleBlur,
    } = this.props;

    const {
      lowAreaOptions,
      resourceOptions,
      openDownload,
      isNewDoc,
      documentHtml,
      draftingNote,
      last_editor,
      saveModalOpen,
      publishModalOpen,
      errorModalOpen,
      last_modified,
      loading,
      errorMessage,
      role,
      openDeleteModal,
      has_subscribers,
      imported_by
    } = this.state;

    const editTime = last_modified ? last_modified : this.props.documentData && this.props.documentData.last_modified
    const lowNext = this.props.lowAreas.next && this.props.lowAreas.next.split('=')[1]
    const resourcesNext = this.props.resources.next && this.props.resources.next.split('=')[1]
    const time = editTime ? new Date(editTime).toLocaleDateString('en-US') : ''
    const openModal = saveModalOpen || publishModalOpen || errorModalOpen
    const modalName = saveModalOpen ? 'saveModal' : publishModalOpen ? 'publishModal' : errorModalOpen ? 'errorModal' : null


    return (
      <div>

        <main className="admin-content manage">
          <div className="edit-doc container">
            <div className="flex justify-between edit-doc-items">
              <div className="edit-doc-content">
                <div className="flex align-center justify-between mb-5 page-header">
                  <h2 className="font-secondary fw-600">EDIT DOCUMENT</h2>
                  <div className="d-flex">
                    <button
                      type={'button'}
                      className="btn filled outline h-sm br-round w-md text-primary border-black-20"
                      onClick={this.onsubmitHandler.bind(this, values, 'save')}
                    >
                      Save Draft
                    </button>
                    <button
                      className="btn filled primary h-sm br-round shadow w-md ml-5"
                      type={'button'}
                      onClick={this.onsubmitHandler.bind(this, values, 'publish')}
                    >
                      Publish
                    </button>
                  </div>
                </div>
                {/* Edit doc content start */}

                {/* For view mode please add "view-mode" class to doc-content div included below */}

                <div className="card doc-content br-corner">
                  <div className="card-header pt-2 pb-2 align-center">
                    <div className='loader'>
                      {
                        loading && (
                          <Loader
                            type="ThreeDots"
                            color="#5386D4"
                            height={20}
                            width={60}
                            timeout={3000}
                          />
                        )
                      }
                    </div>
                    <div className='text-xs-right'>
                      <button className="btn link warning w-sm h-sm fw-600" onClick={this.deleteModalHandler}>
                        <i className="icon-close mr-1"> </i>Delete
                      </button>
                      <button
                        className="btn link primary w-sm h-sm fw-600"
                        onClick={this.handleClick}
                      >
                        <i className="icon-download mr-1"> </i>Download
                      </button>
                      <ReactToPrint
                        onBeforeGetContent={this.makeDocPrintable}
                        trigger={() =>
                          <button className="btn link primary w-sm h-sm fw-600" >
                            <i className="icon-print mr-1"> </i>
                            Print
                          </button>
                        }
                        content={() => this.printableDocument.current}
                      />
                    </div>
                  </div>

                  {/* Delete document popup*/}
                  <DeleteModal
                    openDeleteModal={openDeleteModal}
                    onCloseDelModal={this.deleteModalHandler}
                    handleDelete={this.deleteHandler}
                    content={has_subscribers? DOCUMENT_DELETE_CONFIRM_MESSAGE :DOCUMENT_DELETE_MESSAGE}
                    error={errors.delete}
                  />



                  {/* Save and Publish popup*/}
                  <SaveModal
                    onClose={this.onCloseModal}
                    open={openModal}
                    modalName={modalName}
                    error={errorMessage}
                  />

                  {/* Download popup*/}
                  <DownloadModal
                    selected={this.state.selectedFields}
                    onClose={this.onCloseDownload}
                    open={openDownload}
                    downloadHandler={this.downloadHandler}
                    formFieldChangeHandler={this.formFieldChangeHandler}
                    fileName={this.state.docName}
                  />

                  {
                    !loading && (
                      <div className="card-body">
                        <DocumentEditor
                          cleaned={this.state.is_cleaned}
                          draftingNote={draftingNote}
                          documentHtml={documentHtml}
                          docName={this.state.docName}
                          printableDocument={this.printableDocument}
                          addContentOfDocument={this.addContentOfDocument}
                          historyState={history.location.state}
                        />
                      </div>
                    )
                  }
                </div>

                {/* Edit doc content end */}
              </div>
              {/*DocumentFormStart*/}

              {
                !isNewDoc &&
                <div className="doc-form-content pl-5">
                  <div className="card br-corner pt-4 pb-4 pr-6 pl-6 mb-3">
                    <ul className="list-edit">
                      <li>
                        <label className="label">By:</label>
                        <span className="value">{imported_by}</span>
                      </li>
                      <li>
                        <label className="label">Last edit:</label>
                        <span className="value"> {time} </span>
                      </li>
                    </ul>
                  </div>
                  <div className="card br-corner pt-6 pb-6 pr-6 pl-6">
                    <Form>
                      {
                        role === 'admin' && (
                          <div className="switch-wrap mb-4">
                            <label className="switch">
                              <input
                                onChange={handleChange}
                                checked={values.is_sample}
                                name='is_sample'
                                className="make_public"
                                type="checkbox"
                              />
                              <span className="toggle"> </span>
                            </label>
                            <p className="switch-text">Make public</p>
                          </div>
                        )
                      }
                      <div className={`text-field br-corner ${touched.name && errors.name && 'invalid'}`}>
                        <label>Document title*</label>
                        <Field
                          type="text"
                          name="name"
                          placeholder="*Document title"
                          value={values.name}
                          onChange={handleChange}
                        />
                        {touched.name && errors.name && (
                          <p className="error-msg">{errors.name}</p>
                        )}
                      </div>

                      <Select
                        value={values.resource_type}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        placeholder="Document type"
                        options={resourceOptions}
                        type="resource_type"
                        touched={touched.resource_type}
                        method={this.props.getResourceTypes}
                        page={resourcesNext}
                      />


                      <Select
                        values={values}
                        value={values.law_area}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        placeholder="Legal area"
                        options={lowAreaOptions}
                        type="law_area"
                        touched={touched.law_area}
                        method={this.props.getLowAreas}
                        page={lowNext}
                      />

                      <div
                        className={`text-field br-corner ${
                          touched.imported_by && errors.imported_by && 'invalid'}`}
                      >
                        <label>By*</label>
                        <Field
                          type="text"
                          placeholder="By*"
                          name="imported_by"
                          value={values.imported_by}
                        />
                        {touched.imported_by && errors.imported_by && (
                          <p className="error-msg">{errors.imported_by}</p>
                        )}
                      </div>

                      <div
                        className={`text-field br-corner ${
                          touched.url_imported_by && errors.url_imported_by && 'invalid'}`}
                      >
                        <label>By-Hyperlink</label>
                        <Field
                          type="text"
                          placeholder="By-Hyperlink"
                          name="url_imported_by"
                          value={values.url_imported_by}
                          onChange={handleChange}
                        />

                        {touched.url_imported_by && errors.url_imported_by && (
                          <p className="error-msg">{errors.url_imported_by}</p>
                        )}
                      </div>

                      <label className="checkbox round mt-4 mb-6">
                        <Field
                          type="checkbox"
                          className="checkbox-control"
                          name="andCheckbox"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={
                            !!values.andCheckbox
                              ? !!values.andCheckbox.toString()
                              : undefined
                          }
                        />
                        <span className="check-icon">{''}</span>
                        <span className="checkbox-label">
                          And -
                          <span className="fw-700"> Individual / Organization name</span>
                        </span>
                      </label>

                      <div
                        className={`text-field br-corner ${!values.andCheckbox &&
                        'disabled'} ${touched.imported_with && errors.imported_with && 'invalid'}`}
                      >
                        <label>And*</label>
                        <Field
                          type="text"
                          placeholder="And*"
                          name="imported_with"
                          value={values.imported_with}
                        />
                        {touched.imported_with && errors.imported_with && (
                          <p className="error-msg">{errors.imported_with}</p>
                        )}
                      </div>
                      <div
                        className={`text-field br-corner icon right ${!values.andCheckbox
                        && 'disabled'} ${touched.url_imported_with && errors.url_imported_with && 'invalid'}`}
                      >
                        <label>And-Hyperlink</label>
                        <Field
                          type="text"
                          placeholder="And-Hyperlink"
                          name="url_imported_with"
                          value={values.url_imported_with}
                        />
                        <i className="icon-link">{''}</i>
                        {touched.url_imported_with && errors.url_imported_with && (
                          <p className="error-msg">{errors.url_imported_with}</p>
                        )}
                      </div>
                      <label className={`checkbox round mt-4 mb-6 
                                                ${!values.andCheckbox && 'disabled'} `}>
                        <Field
                          type="checkbox"
                          className="checkbox-control"
                          name="ofCheckbox"
                          onChange={handleChange}
                          disabled={!values.andCheckbox}
                          checked={(values.andCheckbox ? values.ofCheckbox : values.andCheckbox)}

                        />
                        <span className="check-icon"> {''} </span>
                        <span className="checkbox-label">
                                                {' '}
                          Of -
                                                <span className="fw-700">
                                                    {' '}
                                                  Law firm / Organization name
                                                </span>
                                            </span>
                      </label>
                      <div
                        className={`text-field br-corner ${(!values.andCheckbox || !values.ofCheckbox) &&
                        'disabled'} ${touched.organization_imported_with && errors.organization_imported_with && 'invalid'}`}
                      >
                        <label>Of*</label>
                        <Field
                          type="text"
                          placeholder="Of*"
                          name="organization_imported_with"
                          value={values.organization_imported_with}
                        />
                        {touched.organization_imported_with && errors.organization_imported_with && (
                          <p className="error-msg">{errors.organization_imported_with}</p>
                        )}
                      </div>

                      <div
                        className={`text-field br-corner icon right  ${(!values.andCheckbox || !values.ofCheckbox) &&
                        'disabled'} ${touched.url_organization_imported_with && errors.url_organization_imported_with && 'invalid'}`}
                      >
                        <label>Of-Hyperlink</label>
                        <Field
                          type="text"
                          placeholder="Of-Hyperlink"
                          name="url_organization_imported_with"
                          value={values.url_organization_imported_with}
                        />
                        <i className="icon-link">{''}</i>
                        {touched.url_organization_imported_with && errors.url_organization_imported_with && (
                          <p className="error-msg">{errors.url_organization_imported_with}</p>
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              }
            </div>
          </div>
        </main>
        <div style={{ position: 'absolute', zIndex: 0, width: 0, height: 0, overflow: 'hidden'}}>
          <div
            id='printableDocument'
            ref={this.printableDocument}
            style={{ padding: '50px'}}
          > </div>
        </div>
      </div>
    )
  }
}

const EditDocumentFormik = withFormik({
  mapPropsToValues: ({ documentData, currentLawAreas, currentResources }) => {
    const lawAreas = {}
    const resources = {}

    if (currentLawAreas && currentResources) {
      lawAreas.label = currentLawAreas.name
      lawAreas.value = currentLawAreas.id
      resources.label = currentResources.name
      resources.value = currentResources.id
    }

    const andChecked = documentData && documentData.imported_with && true || false
    const ofChecked = documentData && documentData.organization_imported_with && true || false

    return {
      name: (documentData && documentData.name) ? documentData.name : '',
      imported_by: (documentData && documentData.imported_by) ? documentData.imported_by : '',
      url_imported_by: (documentData && documentData.url_imported_by) ? documentData.url_imported_by : '',
      andCheckbox: andChecked,
      imported_with: (documentData && documentData.imported_with) ? documentData.imported_with : '',
      url_imported_with: (documentData && documentData.url_imported_with) ? documentData.url_imported_with : '',
      ofCheckbox: ofChecked,
      organization_imported_with: (documentData && documentData.organization_imported_with) ? documentData.organization_imported_with : '',
      url_organization_imported_with: (documentData && documentData.url_organization_imported_with) ? documentData.url_organization_imported_with : '',
      law_area: lawAreas ? lawAreas : '',
      resource_type: resources ? resources : '',
      is_published: documentData ? documentData.is_published : '',
      is_sample: documentData ?  documentData.is_sample: false,
    }

  },

  validationSchema: Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('This field is mandatory.')
      .max(200, 'Maximum number of symbols is 200.'),
    imported_by: Yup.string()
      .trim()
      .required('This field is mandatory.')
      .max(200, 'Maximum number of symbols is 200.'),
    url_imported_by: Yup.string()
      .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        'The provided link should be valid.')
      .max(200, 'Maximum number of symbols is 200.'),
    imported_with: Yup.string().when('andCheckbox', {
      is: true,
      then: Yup.string()
        .trim()
        .required('This field is mandatory.')
        .max(200, 'Maximum number of symbols is 200.'),
      otherwise: Yup.string()
    }),

    url_imported_with: Yup.string().when('andCheckbox', {
      is: true,
      then: Yup.string()
        .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
          'The provided link should be valid.')
        .max(200, 'Maximum number of symbols is 200.'),
    }),

    organization_imported_with: Yup.string().when('ofCheckbox', {
      is: true,
      then: Yup.string()
        .trim()
        .required('This field is mandatory.')
        .max(200, 'Maximum number of symbols is 200.'),
      otherwise: Yup.string()
    }),

    url_organization_imported_with: Yup.string().when('ofCheckbox', {
      is: true,
      then: Yup.string()
        .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
          'The provided link should be valid.')
        .max(200, 'Maximum number of symbols is 200.'),
    })
  }),
  enableReinitialize: true,
})(EditDocument)

const mapStateToProps = state => {
  return {
    state: state,
    lowAreas: state.documents.lowAreas,
    resources: state.documents.resources,
    documentData: state.documents.documentById.data,
    currentLawAreas: state.documents.lowAreasById.data,
    currentResources: state.documents.resourcesById.data,
  }
}

const mapDispatchToProps = {
  getResourceTypes,
  getLowAreas,
  getLowAreasById,
  getResourcesById,
  documentDownload,
  documentUpdate,
  documentPublish,
  getDocumentHTML,
  getDocumentByIdWithPermission,
  documentDelete,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDocumentFormik)
